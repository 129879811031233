import React, { useState, useEffect } from 'react';
import './pedidos-styles.css';
import {MenuApp, MenuCliente} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {pedidosImg2} from '../../../global/imgs';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {consultarPedidos} from '../../../backend/pedidos/pedidos';
import {AnimatedPage} from '../../../components/animacion/animacion';

function ClientePedidosScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [compras, CambioCompras] = useState([]);
	const [limiteCompras, CambioLimiteCompras] = useState(20);
	const [cuentas, CambioCuentas] = useState([]);
	const [tarjetas, CambioTarjetas] = useState([]);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				consultarPedidos(elUsuario.uid).then(response =>{

					let porTipo = document.getElementById('tipoMostrar').value;
		            let masCuentas = [];
		            let masTarjetas = [];

		            let cero = 0;

		            response.forEach(element =>{

		              if (element.data.datosCompra.producto.data.tipo == 'cuenta') {

		                if (cero <= limiteCompras) {
		                  masCuentas.push(element);
		                }
		              }else if (element.data.datosCompra.producto.data.tipo == 'tarjeta') {

		                if (cero <= limiteCompras) {
		                  masTarjetas.push(element);
		                }
		              }

		              cero++;
		            });

		            if (response.length > 20) {
		              document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
		            }

		            CambioCompras(response);
		            CambioCuentas(masCuentas);
		            CambioTarjetas(masTarjetas);
				})
			}else{

				window.location.href = '/';
			}
		}else{

			window.location.href = '/';
		}
	}

	const verPorTipo = async function(){

	    let porTipo = document.getElementById('tipoMostrar').value;

	    if (porTipo == 'cuenta') {

	      	document.getElementById('theadCuentas').setAttribute('style', '');
	      	document.getElementById('theadTarjetas').setAttribute('style', 'display: none;');
	      	document.getElementById('tbodyCuentas').setAttribute('style', '');
	      	document.getElementById('tbodyTarjetas').setAttribute('style', 'display: none;');
	    }else if (porTipo == 'tarjeta') {

	      	document.getElementById('theadCuentas').setAttribute('style', 'display: none;');
	      	document.getElementById('theadTarjetas').setAttribute('style', '');
	      	document.getElementById('tbodyCuentas').setAttribute('style', 'display: none;');
	      	document.getElementById('tbodyTarjetas').setAttribute('style', '');
	    }
	}

	const verMas = async function(){

	    let porTipo = document.getElementById('tipoMostrar').value;

	    let limite = limiteCompras + 20;

	    let masCuentas = [];
	    let masTarjetas = [];

	    let cero = 0;

	    compras.forEach(element =>{

	      if (element.data.datosCompra.producto.data.tipo == 'cuenta') {

	        if (cero <= limite) {
	          masCuentas.push(element);
	        }
	      }else if (element.data.datosCompra.producto.data.tipo == 'tarjeta') {

	        if (cero <= limite) {
	          masTarjetas.push(element);
	        }
	      }

	      cero++;
	    });


	    CambioCompras(masCuentas);
	    CambioTarjetas(masTarjetas);
	    CambioLimiteCompras(limite);
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuCliente/>
				<div class="container">
					
					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={pedidosImg2}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">Pedidos</h4>
							</div>
						</div>
					</div><br/>

					<div class="row">
			            <div class="col-sm">

			              <div class="mb-3">
			                <label class="form-label">Ver por tipo:</label> <br/>
			                  <select onChange={verPorTipo} id="tipoMostrar" class="form-select form-select-lg mb-3" aria-label="Large select example">
			                    <option value="cuenta">Cuentas streaming</option>
			                    <option value="tarjeta">Tajetas de regalo</option>
			                  </select>
			              </div>

			            </div>
			        </div>

			        <div class="row">
			            <div class="col-sm">
			              <table class="table">

			                <thead id="theadCuentas">
			                  	<tr>
			                  		<th scope="col">ID Orden</th>
			                  		<th scope="col">Fecha de compra</th>
			                  		<th scope="col">Producto</th>
			                    	<th scope="col">Email</th>
			                    	<th scope="col">Password</th>
			                    	<th scope="col">Perfil</th>
			                    	<th scope="col">Duración de la cuenta</th>
			                    	<th scope="col">Fecha de vencimiento</th>
			                  	</tr>
			                </thead>

			                <thead id="theadTarjetas" style={{display: 'none',}}>
			                  	<tr>
			                    	<th scope="col">ID Orden</th>
			                  		<th scope="col">Fecha de compra</th>
			                  		<th scope="col">Producto</th>
			                  		<th scope="col">Código</th>
			                  	</tr>
			                </thead>

			                <tbody id="tbodyCuentas">
			                   {cuentas.map((item) =>(
			                    	<tr>
			                      		<td scope="col">{item.id}</td>
			                      		<td scope="col">{item.data.fecha}</td>
			                      		<td scope="col">
			                      			<div class="productoCompra quitarWrap">

												<img
													src={item.data.datosCompra.producto.data.foto}
													class="fotoProductoBusqueda"
												/>
												<span>{item.data.datosCompra.producto.data.nombre}</span>

											</div>
			                      		</td>
			                      		<td scope="col">{item.data.datosCompra.credencial.data.email}</td>
			                      		<td scope="col">{item.data.datosCompra.credencial.data.password}</td>
			                    		<td scope="col">
			                    				<PerfilCliente
													unPerfil={item.data.datosCompra.perfil}
												/>
			                    		</td>
			                    		<td scope="col">{item.data.datosCompra.tiempo}</td>
			                    		<td scope="col">{item.data.datosCompra.vencimiento}</td>
			                    	</tr>
			                   ))}
			                </tbody>

			                <tbody id="tbodyTarjetas" style={{display: 'none',}}>
			                  	{tarjetas.map((item) =>(
			                    	<tr>
				                        <td scope="col">{item.id}</td>
				                        <td scope="col">{item.data.fecha}</td>
				                        <td scope="col">
			                      			<div class="productoCompra quitarWrap">

												<img
													src={item.data.datosCompra.producto.data.foto}
													class="fotoProductoBusqueda"
												/>
												<span>{item.data.datosCompra.producto.data.nombre}</span>

											</div>
			                      		</td>
			                      		<td scope="col">{item.data.datosCompra.credencial.data.tarjetaCodigo}</td>
			                    	</tr>
			                  	))}
			                </tbody>
			              </table>
			            </div>
			        </div>

			        <div class="row">
			            <div class="col-sm">

			              	<button style={{display: 'none',}} onClick={verMas} class="btn btn-primary" id="verMas">
			                	Ver más
			              	</button><br/><br/>

			            </div>
			        </div>

				</div>
			</div>
		</AnimatedPage>
	);
}

function PerfilCliente({unPerfil}){

	if (unPerfil != 0) {

		return(
			<div class="perfilCliente">
				<span class="datoPerfil">{unPerfil.data.cliente}</span><br/>
				<span class="datoPerfil">{unPerfil.data.password}</span>
			</div>	
		);
	}else{

		return(
			<span style={{color: '#fff', fontWeight: 'bold',}}>Cuenta Completa</span>
		);
	}
}

export default ClientePedidosScreen;