import React, { useState, useEffect } from 'react';
import './categorias-styles.css';
import {MenuApp, MenuAdmin} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {categoriasMenu} from '../../../global/imgs';
import {guardarCategoria, consultarCategorias} from '../../../backend/categorias/categorias';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {AnimatedPage} from '../../../components/animacion/animacion';

function AdminCategoriasScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [nombre, CambioNombre] = useState('');
	const [idCategoria, CambioIdCategoria] = useState(false);
	const [categorias, CambioCategorias] = useState([]);
	const [categoriasFinales, CambioCategoriasFinales] = useState([]);
	const [busquedaCategorias, CambioBusquedaCategorias] = useState(false);
	const [limiteCategorias, CambioLimiteCategorias] = useState(20);
	const [modalEditarCategoria, CambioModalCategoria] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaCategorias == false) {

			consultarCategorias().then(response =>{

				let masCategorias = [];

				let cero = 0;

				response.forEach(element =>{
					if (cero <= limiteCategorias) {
						masCategorias.push(element);
					}
					cero++;
				});

				if (response.length > 20) {
					document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
				}

				CambioCategorias(response);
				CambioCategoriasFinales(masCategorias);
			});

			CambioBusquedaCategorias(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

			}else{

				window.location.href = '/';
			}
		}else{

			window.location.href = '/';
		}
	}

	const visualizarNombreArchivo = async function(idElement, idLabel){

		let fileInput = document.getElementById(idElement);   
		let filename = fileInput.files[0].name; console.log(filename);
		document.getElementById(idLabel).innerHTML = filename;
	}

	const procesarCategoria = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
	    let id = "";
	      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	    let elId = '';
	    let foto = document.getElementById('imgCategoria').files[0];

		if (idCategoria != false) {

			elId = idCategoria;
	    	foto = document.getElementById('imgCategoriaEdit').files[0];
		}else{

			elId = id;
		}

		let data = {
			nombre: nombre,
			foto: foto,
		};

		if (data.nombre === '' || data.foto === '') {

			Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
		}else{

			guardarCategoria(elId, data).then(response =>{
				window.location.reload();
			})
		}
	}

	const verMas = async function(){

		let limite = limiteCategorias + 20;

		let masCategorias = [];

		let cero = 0;

		categorias.forEach(element =>{
			if (cero <= limite) {
				masCategorias.push(element);
			}
			cero++;
		});

		CambioLimiteCategorias(limite);
		CambioCategoriasFinales(masCategorias);
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuAdmin/>
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={categoriasMenu}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">CATEGORÍAS</h4>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">
							<div class="form" style={{margin: 0,}}>
									<div class="headerFormLogin">
										<img
											src={categoriasMenu}
											class="userPlus"
										/>
										<h4 class="tituloHeaderLogin">AGREGAR CATEGORÍA</h4>
									</div>

									<div class="mb-3 containerInput">
									  	<input onChange={() => CambioNombre(document.getElementById('nombre').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="nombre" placeholder="Nombre *" />
									</div>

									<label for="imgCategoria" style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
										<span id="labelImgCategoria">SUBIR IMAGEN</span>
										<input onChange={() => visualizarNombreArchivo('imgCategoria', 'labelImgCategoria')} type="file" id="imgCategoria" style={{display: 'none',}} accept="image/*" />
									</label>

									<button onClick={procesarCategoria} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
										AGREGAR
									</button>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<table class="table">
							  <thead>
							    <tr>
							      <th scope="col">Nombre</th>
							      <th scope="col">Imagen</th>
							      <th scope="col">Acción</th>
							    </tr>
							  </thead>
							  <tbody>
							  	{categoriasFinales.map((item) =>(
							  		<tr>
								      <td>{item.data.nombre}</td>
								      <td>
								      	<img
								      		src={item.data.foto}
								      		style={{width: 100,}}
								      	/>
								      </td>
								      <td>
								      	<button onClick={() =>{
								      		CambioIdCategoria(item.id);
			    							CambioModalCategoria(true);
								      	}} class="btn btn-success">
								      		Editar
								      	</button>
								      </td>
								    </tr>
							  	))}
							  </tbody>
							</table>

						</div>
					</div>

					<div class="row">
						<div class="col-sm">

							<button style={{display: 'none',}} onClick={verMas} class="btn btn-primary" id="verMas">
								Ver más
							</button><br/><br/>

						</div>
					</div>
				</div>
			</div>
			<Modal mostrar={modalEditarCategoria}>
			    <p onClick={() => {
			    	CambioIdCategoria(false);
			    	CambioModalCategoria(false);
			    }} class="equix">X</p>
			    <div class="form" style={{margin: 0,}}>
					<div class="headerFormLogin">
						<img
							src={categoriasMenu}
							class="userPlus"
						/>
						<h4 class="tituloHeaderLogin">EDITAR CATEGORÍA</h4>
					</div>

					<div class="mb-3 containerInput">
						<input onChange={() => CambioNombre(document.getElementById('nombreEdit').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="nombreEdit" placeholder="Nombre *" />
					</div>

					<label for="imgCategoriaEdit" style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
						<span id="labelImgCategoriaEdit">SUBIR IMAGEN</span>
						<input onChange={() => visualizarNombreArchivo('imgCategoriaEdit', 'labelImgCategoriaEdit')} type="file" id="imgCategoriaEdit" style={{display: 'none',}} accept="image/*" />
					</label>

					<button onClick={procesarCategoria} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
						EDITAR
					</button>
				</div>    
			</Modal>
		</AnimatedPage>
	);
}

export default AdminCategoriasScreen;