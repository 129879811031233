import React, { useState, useEffect } from 'react';
import './paises-styles.css';
import {MenuApp, MenuAdmin} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {paisesImg} from '../../../global/imgs';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {guardarPais, consultarPaises, eliminarPais} from '../../../backend/paises/paises';
import {AnimatedPage} from '../../../components/animacion/animacion';

function AdminPaisesScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [nombre, CambioNombre] = useState('');
	const [busquedaPaises, CambioBusquedaPaises] = useState(false);
	const [paises, CambioPaises] = useState([]);
	const [mostrarModalPreguntaPais, CambioMostrarModalPreguntaPais] = useState(false);
	const [idPais, CambioIdPais] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaPaises == false) {

			consultarPaises().then(response =>{

				CambioPaises(response);
			})
			CambioBusquedaPaises(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

			}else{

				window.location.href = '/';
			}
		}else{

			window.location.href = '/';
		}
	}

	const procesarPais = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    let foto = document.getElementById('imgCategoria').files[0];
	    let disponibilidad = document.getElementById('flexCheckDefault').checked;

		if (nombre === '' || foto === undefined) {

			Swal.fire({
	            icon: 'info',
	            title: 'Complete todos los campos para continuar.',
	        });
		}else{

			guardarPais(nombre, foto, disponibilidad).then(response =>{

				window.location.reload();
			})
		}
	}

	const removePais = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    eliminarPais(idPais).then(response =>{
	      window.location.reload();
	    })
	}

	const visualizarNombreArchivo = async function(idElement, idLabel){

		let fileInput = document.getElementById(idElement);   
		let filename = fileInput.files[0].name; console.log(filename);
		document.getElementById(idLabel).innerHTML = filename;
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
				<div class="gridApp">
					<MenuAdmin/>
					<div class="container">

						<div class="row">
							<div class="col-sm">
								<div class="containerTituloSeccion">
									<img
										src={paisesImg}
										class="imgTituloSeccion"
									/>
									<h4 class="tituloSeccion">PAISES</h4>
								</div>
							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="form" style={{margin: 0,}}>
					                <div class="headerFormLogin">
					                    <img
					                      src={paisesImg}
					                      class="userPlus"
					                    />
					                    <h4 id="tituloHeaderLogin" class="tituloHeaderLogin">AGREGAR PAIS</h4>
					                </div>

						            <div class="mb-3 containerInput">
						                <input onChange={() => CambioNombre(document.getElementById('nombre').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="nombre" placeholder="Nombre *" />
						            </div>

						            <label for="imgCategoria" style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
										<span id="labelImgCategoria">SUBIR IMAGEN</span>
										<input onChange={() => visualizarNombreArchivo('imgCategoria', 'labelImgCategoria')} type="file" id="imgCategoria" style={{display: 'none',}} accept="image/*" />
									</label><br/><br/>

									<div style={{textAlign: 'left', marginLeft: 20,}} class="form-check">
									  	<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
									  	<label class="form-check-label" for="flexCheckDefault">
									    	Disponibilidad para transferencias bancarias.
									  	</label>
									</div>

					                <button onClick={procesarPais} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
					                  	AGREGAR
					                </button>
					            </div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<table class="table">
									  <thead>
									    <tr>
									      <th scope="col">Pais</th>
									      <th scope="col">Bandera</th>
									      <th scope="col">Acción</th>
									    </tr>
									  </thead>
									  <tbody>
									  	{paises.map((item) =>(
									  		<tr>
										      <td>{item.data.nombre}</td>
										      <td>
										      	<img
										      		src={item.data.foto}
										      		style={{width: 100,}}
										      	/>
										      </td>
										      <td>
										      	<button onClick={() => {
						                          CambioMostrarModalPreguntaPais(true);
						                          CambioIdPais(item.id);
						                        }} class="btn btn-danger">
										      		Eliminar
										      	</button>
										      </td>
										    </tr>
									  	))}
									  </tbody>
								</table>

							</div>
						</div><br/>
				</div>
			</div>

			<Modal mostrar={mostrarModalPreguntaPais}>
		        <div style={{padding: 20,}}>
		            <h5 class="tituloIniciarSesion sinMargenes">¿Desea eliminar este pais?</h5>
		            <div style={{textAlign: 'center',}}>
		                <button class="btn btn-primary" onClick={removePais}>Si</button>
		                <button onClick={() => {
		                  CambioMostrarModalPreguntaPais(false);
		                  CambioIdPais(false);
		                }} style={{margin: 5,}} class="btn btn-danger">No</button>
		            </div>
		        </div>
		    </Modal>
		</AnimatedPage>
	);
}

export default AdminPaisesScreen;