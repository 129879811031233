import React, { useState, useEffect } from 'react';
import './paises-styles.css';
import {consultarPaises} from '../../backend/paises/paises';

export function ListaPaises({
	mostrar
}) {
	
	const [busquedaPaises, CambioBusquedaPaises] = useState(false);
	const [paises, CambioPaises] = useState([]);

	useEffect(() =>{
		if (busquedaPaises == false) {

			consultarPaises().then(response =>{

				let disponible = [];

				response.forEach(element =>{

					if (element.data.disponibilidad) {

						disponible.push(element);
					}
				})

				CambioPaises(disponible);
			})
			CambioBusquedaPaises(true);
		}
	})

	if (mostrar == 'pagoBanco') {

		return(
			<>
				<h6 class="tituloSeccion">PAISES DISPONIBLES PARA ESTA OPCIÓN</h6>

				<div class="mb-3">
                    <div class="clientes" style={{width: 'fit-content', minHeight: 'fit-content',}}>
                      {paises.map((item) =>(
                          <div class="perfilCliente">
                            <span class="datoPerfil">{item.data.nombre}</span>
                          </div>
                      ))}
                    </div>
                </div>
			</>
		);
	}else{

		return(
			<></>
		);
	}
}