import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function guardarCredencial(data) { console.log(data);

	let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    let id = "";
      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	if (data.tipo == 'cuenta') {

		await set(documento(db, "credenciales", id), {
			email: data.email, 
	        password: data.password, 
	        idProducto: data.idProducto,
	        tipo: data.tipo,
	        cuentaCompleta: data.cuentaCompleta,
		});

		if (data.cuentaCompleta == false) {

			for (let perfil of data.perfiles) {
			
				let id2 = await generarId();

      			await set(documento(db, "perfiles", id2), {
					cliente: perfil.data.cliente,
					password: perfil.data.password,
					idCredencial: id,
				});
			}
		}
	}else{

		await set(documento(db, "credenciales", id), data);
	}

	return true;
}

async function generarId(){

	let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    let id = "";
      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    return id;
}

export async function consultarCredenciales(){

	let credenciales = [];
	const q = buscar(coleccion(db, "credenciales"), donde("tipo", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  credenciales.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	return credenciales;
}

export async function eliminarCredencial(id){

	await eliminarDoc(documento(db, "credenciales", id));

	return true;
}

export async function consultarPerfiles(idCredencial){

	let perfiles = [];
	const q = buscar(coleccion(db, "perfiles"), donde("idCredencial", "==", idCredencial));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  perfiles.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	return perfiles;
}

export async function terminarEliminarPerfil(id){ console.log(id);

	await eliminarDoc(documento(db, "perfiles", id));

	return true;
}