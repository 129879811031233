import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import './App.css';
import 'bootswatch/dist/slate/bootstrap.min.css';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import {validarUsuario} from '../global/validarUsuario';

//Pantallas App
import HomeScreen from './app/home/home';
import LoginScreen from './app/login/login';
import BusquedaScreen from './app/busqueda/busqueda';
import OrdenScreen from './app/orden/orden';

//Pantallas Admin
import AdminHomeScreen from './admin/home/home';
import AdminCategoriasScreen from './admin/categorias/categorias';
import AdminProductosScreen from './admin/productos/productos';
import AdminCredencialesScreen from './admin/credenciales/credenciales';
import AdminUsersScreen from './admin/usuarios/usuarios';
import AdminPaisesScreen from './admin/paises/paises';

//Pantallas Cliente
import ClienteHomeScreem from './cliente/home/home';
import ClienteRecargarScreen from './cliente/recargar/recargar';
import ClientePedidosScreen from './cliente/pedidos/pedidos';
import ClienteRevendedorScreen from './cliente/revendedor/revendedor';

import {Footer} from '../components/footer/footer';

function App() {

  const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

  useEffect(() =>{

    if (usuarioBuscado == false) {

        ponerUsuario();
        CambioUsuarioBuscado(true);
    }
  })

  const ponerUsuario = async function(){

      let elUsuario = await validarUsuario();

      if (elUsuario != false) {

        if (elUsuario.activo == false) {

          await localStorage.clear();

          window.location.href = '/';
        }
      }
  }

  return (
    <Router>
      <div style={{display: 'none',}}>
        <Link to="/">
          Inicio
        </Link>
      </div>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/auth/:form" element={<LoginScreen />} />
        <Route path="/busqueda/:textoBusqueda/:idProducto" element={<BusquedaScreen />} />
        <Route path="/orden/:uid/:idOrden/:cantidad" element={<OrdenScreen />} />
        <Route path="/admin" element={<AdminHomeScreen />} />
        <Route path="/admin/categorias" element={<AdminCategoriasScreen />} />
        <Route path="/admin/productos" element={<AdminProductosScreen />} />
        <Route path="/admin/credenciales" element={<AdminCredencialesScreen />} />
        <Route path="/admin/usuarios" element={<AdminUsersScreen />} />
        <Route path="/admin/paises" element={<AdminPaisesScreen />} />
        <Route path="/cliente" element={<ClienteHomeScreem />} />
        <Route path="/cliente/recargar" element={<ClienteRecargarScreen />} />
        <Route path="/cliente/pedidos" element={<ClientePedidosScreen />} />
        <Route path="/cliente/revendedor" element={<ClienteRevendedorScreen />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
