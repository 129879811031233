import React, { useState, useEffect } from 'react';
import './orden-styles.css';
import {MenuApp} from '../../../components/menu/menu';
import Swal from 'sweetalert2';
import {useParams} from 'react-router-dom';
import {AnimatedPage} from '../../../components/animacion/animacion';
import {asignarCreditos} from '../../../backend/recargar/recargar';

function OrdenScreen(){

	const routeParams = useParams();
  	const uid = routeParams.uid;
  	const cantidad = routeParams.cantidad;

  	const [procesanOrden, CambioProcesanOrden] = useState(false);

  	useEffect(() =>{

  		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    if (procesanOrden == false) {

	    	asignarCreditos(uid, cantidad).then(response =>{

	    		window.location.href = 'http://venetgoplay.com/cliente/recargar';
	    	})

	    	CambioProcesanOrden(true);
	    }
  	})

  	return(
  		<AnimatedPage duracion={1}>
			<MenuApp/>
			<div class="container" style={{minHeight: '100vh',}}>
			</div>
		</AnimatedPage>
	);
}

export default OrdenScreen;