import React, { useState, useEffect } from 'react';
import './categorias-styles.css';
import {consultarCategorias} from '../../backend/categorias/categorias';

export function NombreCategorias({idCategoria}){

	const [nombre, CambioNombre] = useState('');
	const [busquedaCategorias, CambioBusquedaCategorias] = useState(false);

	useEffect(() =>{

		if (busquedaCategorias == false) {

			consultarCategorias().then(response =>{

				response.forEach(element =>{

					if (element.id == idCategoria) {
						CambioNombre(element.data.nombre);
					}
				})
			});

			CambioBusquedaCategorias(true);
		}
	})

	return(
		<span>{nombre}</span>
	);
}