export const logoMenu = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2FlogoVenetGoPlay.png?alt=media&token=fb23c482-83a7-4bba-a710-079fe1ff8cbf';

export const userPlus = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2FuserPlus.png?alt=media&token=e878fd20-453b-4679-923c-f898cbc82644';

export const user = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2Fuser.png?alt=media&token=7bce5c1f-e840-4ac8-b17f-7b1627a8116f';

export const mail = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2Fmail.png?alt=media&token=31fab636-ddac-4d6a-a20c-8a178eae6667';

export const passwordImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2Fpassword.png?alt=media&token=86bf0ac1-49a8-44be-adf6-def48670f5bc';

export const eye = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2Feye.png?alt=media&token=e3c2ab47-635d-4cc7-950d-5d67334de2a8';

export const eyeNo = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2FeyeNo.png?alt=media&token=9803fd7a-9c31-4e99-83c7-8858961317f3';

export const userPlusBlanco = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2FuserPlusBlanco.png?alt=media&token=fe71f5f1-de81-4b7a-bab7-80244e29b46a';

export const userLock = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2FuserLock.png?alt=media&token=1405ac7e-f869-4b0c-8706-6f304f17a7cd';

export const enter = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Flogin%2Fenter.png?alt=media&token=06e1de14-9b25-4131-9cd8-e0a78fac4fbd';

export const categoriasMenu = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2FcategoriasMenu.png?alt=media&token=28c2228a-c7c4-4529-95d9-3a45a97bfbe9';

export const folder = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2Fordenes.png?alt=media&token=59c64d1b-f9b4-48af-b220-b8633e90d899';

export const carritoBlanco = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2FvarritoBlanco.png?alt=media&token=e1b3fda1-f977-417d-ac86-0cb9722c93ba';

export const userImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2Fuser2.png?alt=media&token=0e15f5c7-e79d-41d7-a27f-93fd497d3d8d';

export const ticket = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2Fticket.png?alt=media&token=09c7f26e-bc8d-4fed-87d1-935c2f3e95f8';

export const pedidosImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2Fpedidos.png?alt=media&token=e7372e9a-e3ad-4251-9279-1b1db7a2aef2';

export const recargasImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2Frecargas.png?alt=media&token=e1193dc3-4592-4874-8c4b-eaf1ade63c00';

export const camPerfil = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fperfil%2FcamPerfil.png?alt=media&token=c68a607f-e103-44e5-82dd-bba024b5e9e0';

export const logoBinance = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Frecargar%2FBinance_Logo.svg.png?alt=media&token=339fe1ac-d4cf-4f79-be9d-1bb686496b06';

export const banco = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Frecargar%2Fbanco.png?alt=media&token=25faf7cb-cd28-4c3e-a930-f14eec0b7455';

export const paisesImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2FpaisesImg.png?alt=media&token=7b95cd7e-3a2e-48bc-95d7-a3d4c709e2ef';

export const fotoImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2Ffoto.png?alt=media&token=f731c368-2e32-4f02-8d0a-144244d5ea36';

export const digitalImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2Fdigital.png?alt=media&token=3bd8ad8e-21d4-4077-9be1-f445e506ac47';

export const relojImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2Freloj.png?alt=media&token=989f009c-cd12-48b0-9083-c4975e3123d9';

export const monitorImg = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2Fmonitor.png?alt=media&token=01554f07-08db-4ab5-9d35-02b585b019d8';

export const pedidosImg2 = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2FpedidosImg.png?alt=media&token=23b7f5fd-e37e-41ba-b8c0-1d081c1352cc';

export const spotify = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2Fspotify.png?alt=media&token=1dbacfee-cb5a-42a0-b61c-33fafc232560';

export const devices = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2Fdevices.png?alt=media&token=c842aabf-d40d-4c27-84ba-bc02bfae2d46';

export const iconoMenu = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fmenu%2FiconoMenu.png?alt=media&token=1b6a85eb-44ca-4d54-a074-76c80d03c030';

export const instagram = 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fhome%2FinstagramVenet.png?alt=media&token=366ea6f9-786d-46c0-a114-1165ab0db836';