import React, { useState, useEffect } from 'react';
import './menu-styles.css';
import {
	logoMenu, 
	categoriasMenu, 
	passwordImg, 
	folder, 
	userImg, 
	recargasImg, 
	paisesImg,
	pedidosImg2,
	spotify,
	iconoMenu
} from '../../global/imgs';
import {EstadoUsuario} from '../estadoUsuario/estadoUsuario';
import {validarUsuario} from '../../global/validarUsuario';
import {consultarProductos} from '../../backend/productos/productos';
import {Link} from 'react-router-dom';
import {Modal} from '../../components/modals/modals';

export function MenuApp(){

	const [productos, CambioProductos] = useState([]);
  	const [productosFinales, CambioProductosFinales] = useState([]);
  	const [limiteProductos, CambioLimiteProductos] = useState(20);
  	const [busquedaProductos, CambioBusquedaProductos] = useState(false);
	
	useEffect(() =>{

		if (busquedaProductos == false) {

		      consultarProductos().then(response =>{

		        CambioProductos(response);
		      });

		      CambioBusquedaProductos(true);
		}
	})

	const buscarProducto = async function(){

	    let query = document.getElementById('buscarMenu').value;

	    if (query === '') {

	    	document.getElementById('listaBusqueda').setAttribute('style', 'display: none;');
	    	CambioProductosFinales([]);
	    }else{
	        
	        document.getElementById('listaBusqueda').setAttribute('style', 'display: block;');

	        let resultado = [];

	        const datos = productos;

	        const regex = new RegExp(`${query.trim()}`, 'i');

	        const laBusqueda = datos.filter(datos => datos.data.nombre.search(regex) >= 0);

	        CambioProductosFinales(laBusqueda);
	    }
	}

	return(
		<div class="menuApp">
			<div class="iconoYBuscador">
				<Link to='/'>
					<img
						class="logoMenu"
						src={logoMenu}
					/>
				</Link>

				<div class="formBusqueda">

					<div class="mb-3">
					  <input onChange={buscarProducto} style={{backgroundColor: '#404040', marginTop: 15, color: '#fff', border: '1px solid #3ad32b',}} class="form-control" id="buscarMenu" placeholder="Buscar" />
					</div>

					<div class="listaBusqueda" id="listaBusqueda">
						{productosFinales.map((item) =>(
							<Link to={"/busqueda/"+document.getElementById('buscarMenu').value+"/"+item.id} class="productoBusqueda">

								<img
									src={item.data.foto}
									class="fotoProductoBusqueda"
								/>
								<span class="nombreProductoBusqueda">{item.data.nombre}</span>

							</Link>
						))}
					</div>
				</div>
			</div>

			<EstadoUsuario/>
		</div>
	);
}

export function MenuAdmin(){

	const [usuario, CambioUsuario] = useState(false);
	const [modalMenu, CambioModalMenu] = useState(false);

	useEffect(() =>{

		if (usuario == false) {
			ponerUsuario();
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		CambioUsuario(elUsuario);

		if (elUsuario.rol == 'subAdmin') {

			document.getElementById('losSubAdmin').setAttribute('style', 'display: none;');
		}
	}

	return(
		<>	<div>
				<div onClick={() => CambioModalMenu(true)} class="menuResponsive">
					<img
						src={iconoMenu}
						class="iconoMenu"
					/>
					<span class="textoNuevoMenu">Menú</span>
				</div>
				<div class="MenuAdmin" id="MenuAdmin">
					<Link to='/admin' class="opcionMenu">
						<img
							src={userImg}
							class="imgOpcionMenu"
						/>
						Perfil
					</Link>
					<Link to='/admin/usuarios' class="opcionMenu">
						<img
							src={userImg}
							class="imgOpcionMenu"
						/>
						Usuarios
					</Link>
					<Link to='/admin/categorias' class="opcionMenu">
						<img
							src={categoriasMenu}
							class="imgOpcionMenu"
						/>
						Categorías
					</Link>
					<Link to='/admin/credenciales' class="opcionMenu">
						<img
							src={passwordImg}
							class="imgOpcionMenu"
						/>
						Credenciales
					</Link>
					<Link to='/admin/productos' class="opcionMenu">
						<img
							src={folder}
							class="imgOpcionMenu"
						/>
						Productos
					</Link>

					<Link to='/admin/paises' class="opcionMenu">
						<img
							src={paisesImg}
							class="imgOpcionMenu"
						/>
						Paises
					</Link>
				</div>
			</div>
			<Modal mostrar={modalMenu}>
				<p onClick={() => {
			    	CambioModalMenu(false);
			    }} class="equix">X</p>

			    <div class="MenuAdmin">
					<Link to='/admin' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={userImg}
							class="imgOpcionMenu"
						/>
						Perfil
					</Link>
					<Link to='/admin/usuarios' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={userImg}
							class="imgOpcionMenu"
						/>
						Usuarios
					</Link>
					<Link to='/admin/categorias' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={categoriasMenu}
							class="imgOpcionMenu"
						/>
						Categorías
					</Link>
					<Link to='/admin/credenciales' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={passwordImg}
							class="imgOpcionMenu"
						/>
						Credenciales
					</Link>
					<Link to='/admin/productos' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={folder}
							class="imgOpcionMenu"
						/>
						Productos
					</Link>

					<Link to='/admin/paises' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={paisesImg}
							class="imgOpcionMenu"
						/>
						Paises
					</Link>
				</div>
			</Modal>
		</>
	);
}

export function MenuCliente(){

	const [usuario, CambioUsuario] = useState(false);
	const [modalMenu, CambioModalMenu] = useState(false);

	useEffect(() =>{

		if (usuario == false) {
			ponerUsuario();
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		CambioUsuario(elUsuario);

		if (elUsuario.revendedor) {

			document.getElementById('losSubAdmin').setAttribute('style', '');
		}
	}

	return(
		<>
			<div onClick={() => CambioModalMenu(true)} class="menuResponsive">
				<img
					src={iconoMenu}
					class="iconoMenu"
				/>
				<span class="textoNuevoMenu">Menú</span>
			</div>
			<div class="MenuAdmin" id="MenuAdmin">
				<Link to='/cliente' class="opcionMenu">
					<img
						src={userImg}
						class="imgOpcionMenu"
					/>
					Perfil
				</Link>

				<Link to='/cliente/pedidos' class="opcionMenu">
					<img
						src={pedidosImg2}
						class="imgOpcionMenu"
					/>
					Pedidos
				</Link>

				<Link to='/cliente/recargar' class="opcionMenu">
					<img
						src={recargasImg}
						class="imgOpcionMenu"
					/>
					Recargar
				</Link>

				<Link style={{display: 'none',}} id="losSubAdmin" to='/cliente/revendedor' class="opcionMenu">
					<img
						src={spotify}
						class="imgOpcionMenu"
					/>
					Revendedor
				</Link>
			</div>
			<Modal mostrar={modalMenu}>
				<p onClick={() => {
			    	CambioModalMenu(false);
			    }} class="equix">X</p>

			    <div class="MenuAdmin">
					<Link to='/cliente' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={userImg}
							class="imgOpcionMenu"
						/>
						Perfil
					</Link>

					<Link to='/cliente/pedidos' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={pedidosImg2}
							class="imgOpcionMenu"
						/>
						Pedidos
					</Link>

					<Link to='/cliente/recargar' onClick={() => CambioModalMenu(false)} class="opcionMenu">
						<img
							src={recargasImg}
							class="imgOpcionMenu"
						/>
						Recargar
					</Link>

					<Link style={{display: 'none',}} onClick={() => CambioModalMenu(false)} id="losSubAdmin" to='/cliente/revendedor' class="opcionMenu">
						<img
							src={spotify}
							class="imgOpcionMenu"
						/>
						Revendedor
					</Link>
				</div>
			</Modal>
		</>
	);
}