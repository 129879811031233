import React, { useState, useEffect } from 'react';
import './home-styles.css';
import {MenuApp, MenuCliente} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {userImg, camPerfil, mail, user, passwordImg} from '../../../global/imgs';
import {actulizarFotoPerfil, actualizarDatos, guardarNuevaPassword} from '../../../backend/perfil/perfil';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {AnimatedPage} from '../../../components/animacion/animacion';

function ClienteHomeScreem(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [imgUsuario, CambioImgUsuario] = useState('https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fuser%2Fimages.jpeg?alt=media&token=e782f4b3-7b5c-4335-8e6d-d20d489d854f');
	const [nombreUsuario, CambioNombreUsuario] = useState('');
	const [emailUsuario, CambioEmailUsuario] = useState('');
	const [editNombre, CambioEditNombre] = useState('');
	const [editEmail, CambioEditEmail] = useState('');
	const [password, CambioPassword] = useState('');
	const [modalDatos, CambioModalDatos] = useState(false);
	const [modalPassword, CambioModalPassword] = useState(false);
	const [passwordNueva, CambioPasswordNueva] = useState('');
	const [passwordConfirmar, CambioPasswordConfirmar] = useState('');

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				CambioImgUsuario(elUsuario.foto);
				CambioNombreUsuario(elUsuario.nombre);
				CambioEmailUsuario(elUsuario.email);
			}else{

				window.location.href = '/';
			}
		}else{

			window.location.href = '/';
		}
	}

	const subirImagen = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    let fileInput = document.getElementById('cambiaFoto').files[0];

	    let elUsuario = await validarUsuario();

	    if (elUsuario != false) {

	    	actulizarFotoPerfil(elUsuario.uid, fileInput).then(response =>{

	    		if (response != false) {

	    			terminar(response);
	    		}else{

	    			Swal.fire({
			            icon: 'error',
			            title: 'Lo sentimos pero no pudimos actualizar sus datos.',
			        });
	    		}
	    	})
	    } 
	}

	const terminar = async function(data){

		await localStorage.setItem('usuarioVenetGoPlay', JSON.stringify(data));

		window.location.reload();
	}

	const procesarDatos = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

		if (editNombre === '' || editEmail === '' || password === '') {

			Swal.fire({
			    icon: 'info',
			    title: 'Complete todos los campos para continuar.',
			});
		}else{

			let elUsuario = await validarUsuario();

			actualizarDatos(editNombre, editEmail, password, elUsuario).then(response =>{

				if (response != false) {

	    			terminar(response);
	    		}else{

	    			Swal.fire({
			            icon: 'error',
			            title: 'Lo sentimos pero no pudimos actualizar sus datos.',
			        });
	    		}
			})
		}
	}

	const procesarPassword = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    if (password === '' || passwordNueva === '' || passwordConfirmar === '') {

	    	Swal.fire({
			    icon: 'info',
			    title: 'Complete todos los campos para continuar.',
			});
	    }else if (passwordNueva != passwordConfirmar) {

	    	Swal.fire({
			    icon: 'info',
			    title: 'La contraseña no coincide.',
			});
	    }else{

	    	guardarNuevaPassword(password, passwordNueva).then(response =>{

	    		if (response != false) {

	    			window.location.reload();
	    		}else{

	    			Swal.fire({
			            icon: 'error',
			            title: 'Lo sentimos pero no pudimos actualizar sus datos.',
			        });
	    		}
	    	})
	    }
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuCliente/>
				<div class="container">
					
					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={userImg}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">PERFIL</h4>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">
							
							<div class="containerFotoPerfil">

								<label for="cambiaFoto" class="containerCamPerfil">
									<img
										src={camPerfil}
										class="camPerfil"
									/>
									<input onChange={subirImagen} type="file" id="cambiaFoto" style={{display: 'none',}} accept="image/*" />
								</label>

								<img
									src={imgUsuario}
									class="imgUsuarioPerfil"
								/>

							</div>

						</div>
					</div>

					<div class="row">
						<div class="col-sm">

							<div class="containerNombreEmail">
								<p style={{color: '#fff',}}>Nombre: {nombreUsuario}</p>
								<p style={{color: '#fff',}}>Email: {emailUsuario}</p>
								<button onClick={() =>{
									CambioEditNombre(nombreUsuario);
									CambioEditEmail(emailUsuario);
									CambioModalDatos(true);
								}} class="btn btn-success">Editar</button>
							</div>

						</div>
					</div>

					<div class="row">
						<div class="col-sm">

							<button onClick={() =>{
								CambioModalPassword(true);
							}} class="btn btn-success" style={{display: 'block', margin: 'auto', marginTop: 20,}}>Editar Contraseña</button>

						</div>
					</div>

				</div>
			</div>

			<Modal mostrar={modalDatos}>
			    <p onClick={() => {
			    	CambioModalDatos(false)
			    	CambioEditNombre('');
					CambioEditEmail('');
			    }} class="equix">X</p>
			    <div class="containerRecuperar">
			        <h5 class="tituloIniciarSesion sinMargenes">EDITAR DATOS</h5>
			        <div class="mb-3 containerInput">
						<img
							src={user}
							class="imgLabel"
						/>
						<input onChange={() => CambioEditNombre(document.getElementById('editNombre').value)} value={editNombre} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="editNombre" placeholder="Nombre" />
					</div>
			        <div class="mb-3 containerInput">
						<img
							src={mail}
							class="imgLabel"
						/>
						<input onChange={() => CambioEditEmail(document.getElementById('editEmail').value)} value={editEmail} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="editEmail" placeholder="Correo" />
					</div>
					<div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña *" />
					</div>
			        <button onClick={procesarDatos} class="btn btn-info" style={{marginTop: 20,}}>GUARDAR</button>
			    </div>
			</Modal>

			<Modal mostrar={modalPassword}>
			    <p onClick={() => {
			    	CambioModalPassword(false);
			    }} class="equix">X</p>
			    <div class="containerRecuperar">
			        <h5 class="tituloIniciarSesion sinMargenes">CAMBIAR CONTRASEÑA</h5>

			        <div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña Actual *" />
					</div>

					<div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPasswordNueva(document.getElementById('passwordNueva').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="passwordNueva" placeholder="Contraseña Nueva *" />
					</div>

					<div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPasswordConfirmar(document.getElementById('passwordConfirmar').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="passwordConfirmar" placeholder="Confirmar Contraseña *" />
					</div>
			        <button onClick={procesarPassword} class="btn btn-info" style={{marginTop: 20,}}>GUARDAR</button>
			    </div>
			</Modal>
		</AnimatedPage>
	);
}

export default ClienteHomeScreem;