import React, { useState, useEffect } from 'react';
import './credenciales-styles.css';
import {MenuApp, MenuAdmin} from '../../../components/menu/menu';
import {NombreProducto, ListaPerfiles} from '../../../components/productos/productos';
import {validarUsuario} from '../../../global/validarUsuario';
import {passwordImg, eye, eyeNo, mail} from '../../../global/imgs';
import {consultarProductos} from '../../../backend/productos/productos';
import {guardarCredencial, consultarCredenciales, eliminarCredencial} from '../../../backend/credenciales/credenciales';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {AnimatedPage} from '../../../components/animacion/animacion';

function AdminCredencialesScreen() {

  const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
  const [mostrarPassword, CambioMostrarPassword] = useState(eye);
  const [email, CambioEmail] = useState('');
  const [password, CambioPassword] = useState('');
  const [tipo, CambioTipo] = useState(false);
  const [productos, CambioProductos] = useState([]);
  const [busquedaProductos, CambioBusquedaProductos] = useState(false);
  const [tarjetaCodigo, CambioTarjetaCodigo] = useState('');
  const [credenciales, CambioCredenciales] = useState([]);
  const [cuentas, CambioCuentas] = useState([]);
  const [tarjetas, CambioTarjetas] = useState([]);
  const [limiteCredenciales, CambioLimiteCredenciales] = useState(20);
  const [busquedaCredenciales, CambioBusquedaCredenciales] = useState(false);
  const [mostrarModalPreguntaCredencial, CambioMostrarModalPreguntaCredencial] = useState(false);
  const [idCredencial, CambioIdCredencial] = useState(false);
  const [cliente, CambioCliente] = useState('');
  const [passwordCliente, CambioPasswordCliente] = useState('');
  const [perfiles, CambioPerfiles] = useState([]);
  const [perfilesCambiados, CambioPerfilesCambiados] = useState(true);

  useEffect(() =>{

    if (usuarioBuscado == false) {

      ponerUsuario();
      CambioUsuarioBuscado(true);
    }

    if (busquedaProductos == false) {

      consultarProductos().then(response =>{

        CambioProductos(response);
      });

      CambioBusquedaProductos(true);
    }

    if (busquedaCredenciales == false) {

      consultarCredenciales().then(response =>{

            let porTipo = document.getElementById('tipoMostrar').value;
            let masCuentas = [];
            let masTarjetas = [];

            let cero = 0;

            response.forEach(element =>{

              if (element.data.tipo == 'cuenta') {

                if (cero <= limiteCredenciales) {
                  masCuentas.push(element);
                }
              }else if (element.data.tipo == 'tarjeta') {

                if (cero <= limiteCredenciales) {
                  masTarjetas.push(element);
                }
              }

              cero++;
            });

            if (response.length > 20) {
              document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
            }

            CambioCredenciales(response);
            CambioCuentas(masCuentas);
            CambioTarjetas(masTarjetas);
      })

      CambioBusquedaCredenciales(true)
    }
  })

  const ponerUsuario = async function(){

    let elUsuario = await validarUsuario();

    if (elUsuario != false) {

      if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

      }else{

        window.location.href = '/';
      }
    }else{

      window.location.href = '/';
    }
  }

  const verPassword = async function(){

    if (mostrarPassword == eye) {

      document.getElementById('password').setAttribute("type", "text");
      CambioMostrarPassword(eyeNo);
    }else if (mostrarPassword == eyeNo) {

      document.getElementById('password').setAttribute("type", "password");
      CambioMostrarPassword(eye);
    }
  }

  const procesarCredencial = async function(){

      Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
      });

      if (tipo == false) {

        Swal.fire({
          icon: 'info',
          title: 'Seleccione el tipo de credencial.',
        });
      }else if (tipo == 'cuenta') {

        let validarCampos = true;

        if (document.getElementById('cuentaCompleta').checked == true) {

          if (email === '' || password === '' || document.getElementById('producto').value === '') {

            validarCampos = false;
          }
        }else if (document.getElementById('cuentaCompleta').checked == false) {

          if (email === '' || password === '' || document.getElementById('producto').value === '' || perfiles.length == 0) {

            validarCampos = false;
          }
        }

        if (validarCampos == false) {

          Swal.fire({
            icon: 'info',
            title: 'Complete todos los campos para continuar.',
          });
        }else{

          let data = {
            email: email, 
            password: password, 
            idProducto: document.getElementById('producto').value,
            tipo: tipo,
            perfiles: perfiles,
            cuentaCompleta: document.getElementById('cuentaCompleta').checked,
          };

          guardarCredencial(data).then(response =>{
            window.location.reload();
          })
        }
      }else if (tipo == 'tarjeta') {

        if (tarjetaCodigo === '' || document.getElementById('producto').value === '') {

          Swal.fire({
            icon: 'info',
            title: 'Complete todos los campos para continuar.',
          });
        }else{

          let data = {
            tarjetaCodigo: tarjetaCodigo,
            idProducto: document.getElementById('producto').value,
            tipo: tipo,
          };

          guardarCredencial(data).then(response =>{
            window.location.reload();
          })
        }
      }
  }

  const verOtroTipo = async function(elTipo){

    if (elTipo == 'cuenta') {

      document.getElementById('cuentaEmail').setAttribute('style', '');
      document.getElementById('cuentaPassword').setAttribute('style', '');
      document.getElementById('formPerfiles').setAttribute('style', '');
      document.getElementById('clientes').setAttribute('style', '');
      document.getElementById('cuentaCompletaCheck').setAttribute('style', 'text-align: left; margin-left: 20px;');

      document.getElementById('tarjetaCodigoLabel').setAttribute('style', 'display: none;');

      CambioTipo(elTipo);
    }else if (elTipo == 'tarjeta') {

      document.getElementById('cuentaEmail').setAttribute('style', 'display: none;');
      document.getElementById('cuentaPassword').setAttribute('style', 'display: none;');
      document.getElementById('formPerfiles').setAttribute('style', 'display: none;');
      document.getElementById('clientes').setAttribute('style', 'display: none;');
      document.getElementById('cuentaCompletaCheck').setAttribute('style', 'text-align: left; margin-left: 20px; display: none;');

      document.getElementById('tarjetaCodigoLabel').setAttribute('style', '');

      CambioTipo(elTipo);
    }
  }

  const verPorTipo = async function(){

    let porTipo = document.getElementById('tipoMostrar').value;

    if (porTipo == 'cuenta') {

      document.getElementById('theadCuentas').setAttribute('style', '');
      document.getElementById('theadTarjetas').setAttribute('style', 'display: none;');
      document.getElementById('tbodyCuentas').setAttribute('style', '');
      document.getElementById('tbodyTarjetas').setAttribute('style', 'display: none;');
    }else if (porTipo == 'tarjeta') {

      document.getElementById('theadCuentas').setAttribute('style', 'display: none;');
      document.getElementById('theadTarjetas').setAttribute('style', '');
      document.getElementById('tbodyCuentas').setAttribute('style', 'display: none;');
      document.getElementById('tbodyTarjetas').setAttribute('style', '');
    }
  }

  const verMas = async function(){

    let porTipo = document.getElementById('tipoMostrar').value;

    let limite = limiteCredenciales + 20;

    let masCuentas = [];
    let masTarjetas = [];

    let cero = 0;

    credenciales.forEach(element =>{

      if (element.data.tipo == 'cuenta') {

        if (cero <= limite) {
          masCuentas.push(element);
        }
      }else if (element.data.tipo == 'tarjeta') {

        if (cero <= limite) {
          masTarjetas.push(element);
        }
      }

      cero++;
    });


    CambioCuentas(masCuentas);
    CambioTarjetas(masTarjetas);
    CambioLimiteCredenciales(limite);
  }

  const removeCredencial = async function(){
    Swal.fire({
      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
          '</div>',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
    });

    eliminarCredencial(idCredencial).then(response =>{
      window.location.reload();
    })
  }

  const agregarCliente = async function(){

    let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    let id = "";
      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    if (cliente === '' || passwordCliente === '') {

      Swal.fire({
        icon: 'info',
        title: 'Complete todos los campos para continuar.',
      });
    }else{

      let losPerfiles = perfiles;
      losPerfiles.push({
        id: id,
        data:{
          cliente: cliente,
          password: passwordCliente,
          idCredencial: '',
        },
      });

      CambioCliente('');
      document.getElementById('cliente').value = '';
      CambioPasswordCliente('');
      document.getElementById('passwordCliente').value = '';
      CambioPerfiles(losPerfiles);
    }
  }

  const quitarCuenta = async function(item){

    let losPerfiles = perfiles;
    let index = losPerfiles.indexOf(item);

    losPerfiles.splice(index, 1); console.log(losPerfiles);

    CambioCliente('');
    CambioPasswordCliente('');
    CambioPerfiles(losPerfiles);
    document.getElementById(item.id).setAttribute('style', 'display: none;');
  }

  return(
    <AnimatedPage duracion={0.5}>
      <MenuApp/>
      <div class="gridApp">
        <MenuAdmin/>
        <div class="container">

          <div class="row">
            <div class="col-sm">

              <div class="containerTituloSeccion">
                <img
                  src={passwordImg}
                  class="imgTituloSeccion"
                />
                <h4 class="tituloSeccion">CREDENCIALES</h4>
              </div>

            </div>
          </div><br/>

          <div class="row">
            <div class="col-sm">
              <div class="form" style={{margin: 0,}}>
                  <div class="headerFormLogin">
                    <img
                      src={passwordImg}
                      class="userPlus"
                    />
                    <h4 class="tituloHeaderLogin">AGREGAR CREDENCIAL</h4>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Tipo *</label> <br/>
                      <div class="form-check">
                        <input onClick={() => verOtroTipo('cuenta')} class="form-check-input" type="radio" name="flexRadioDefault" id="cuenta" />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Cuenta Streaming
                        </label>
                      </div>
                      <div class="form-check">
                        <input onClick={() => verOtroTipo('tarjeta')} class="form-check-input" type="radio" name="flexRadioDefault" id="tarjeta" />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Tarjeta de regalo
                        </label>
                      </div>
                  </div>

                  <div id="tarjetaCodigoLabel" style={{display: 'none',}} class="mb-3 containerInput">
                    <img
                      src={passwordImg}
                      class="imgLabel"
                    />
                      <input onChange={() => CambioTarjetaCodigo(document.getElementById('tarjetaCodigo').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="tarjetaCodigo" placeholder="Código *" />
                  </div>

                  <div id="cuentaEmail" style={{display: 'none',}} class="mb-3 containerInput">
                    <img
                      src={mail}
                      class="imgLabel"
                    />
                      <input onChange={() => CambioEmail(document.getElementById('email').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="email" placeholder="Correo *" />
                  </div>

                  <div id="cuentaPassword" style={{display: 'none',}} class="mb-3 containerInput">
                    <img
                      src={passwordImg}
                      class="imgLabel"
                    />
                      <input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña *" />
                    <img
                      src={mostrarPassword}
                      class="imgLabel"
                      onClick={verPassword}
                    />
                  </div>

                  <div id="cuentaCompletaCheck" style={{textAlign: 'left', marginLeft: 20, display: 'none',}} class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="cuentaCompleta" />
                      <label class="form-check-label" for="flexCheckDefault">
                        Cuenta completa <span style={{color: '#3ad32b', fontWeight: 'bold', cursor: 'pointer',}}>(Si defines como cuenta completa, no hay necesidad de agregar perfiles)</span>
                      </label>
                  </div>

                  <div id="formPerfiles" class="form" style={{display: 'none',}}>
                      <div class="mb-3 containerInput">
                        <img
                          src={mail}
                          class="imgLabel"
                        />
                          <input value={cliente} onChange={() => CambioCliente(document.getElementById('cliente').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="cliente" placeholder="Cliente *" />
                      </div>

                      <div class="mb-3 containerInput">
                        <img
                          src={passwordImg}
                          class="imgLabel"
                        />
                        <input value={passwordCliente} onChange={() => CambioPasswordCliente(document.getElementById('passwordCliente').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="passwordCliente" placeholder="Contraseña Cliente *" />
                      </div>

                      <button onClick={agregarCliente} style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
                        AGREGAR PERFIL
                      </button>
                  </div>

                  <div class="mb-3" id="clientes" style={{display: 'none',}}>
                    <div class="clientes">
                      {perfiles.map((item) =>(
                          <div id={item.id} class="perfilCliente">
                            <p onClick={() => quitarCuenta(item)} class="equix">X</p>
                            <span class="datoPerfil">{item.data.cliente}</span><br/>
                            <span class="datoPerfil">{item.data.password}</span>
                          </div>
                      ))}
                    </div>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Producto *</label> <br/>
                      <select id="producto" class="form-select form-select-lg mb-3" aria-label="Large select example">
                        <option value="">Seleccione producto</option>
                        {productos.map((item) =>(
                          <option value={item.id}>{item.data.nombre}</option>
                        ))}
                      </select>
                  </div>


                  <button onClick={procesarCredencial} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
                    AGREGAR
                  </button>
              </div>
            </div>
          </div><br/>

          <div class="row">
            <div class="col-sm">

              <div class="mb-3">
                <label class="form-label">Ver por tipo:</label> <br/>
                  <select onChange={verPorTipo} id="tipoMostrar" class="form-select form-select-lg mb-3" aria-label="Large select example">
                    <option value="cuenta">Cuentas streaming</option>
                    <option value="tarjeta">Tajetas de regalo</option>
                  </select>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <table class="table">

                <thead id="theadCuentas">
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Password</th>
                    <th scope="col">Perfiles</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>

                <thead id="theadTarjetas" style={{display: 'none',}}>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>

                <tbody id="tbodyCuentas">
                  {cuentas.map((item) =>(
                    <tr>
                      <td scope="col">{item.data.email}</td>
                      <td scope="col">{item.data.password}</td>
                      <td scope="col">
                        <ListaPerfiles
                          idCredencial={item.id}
                          cuentaCompleta={item.data.cuentaCompleta}
                        />
                      </td>
                      <td scope="col">
                        <NombreProducto
                          idProducto={item.data.idProducto}
                        />
                      </td>
                      <td scope="col">
                        <button onClick={() => {
                          CambioMostrarModalPreguntaCredencial(true);
                          CambioIdCredencial(item.id);
                        }} class="btn btn-danger">
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tbody id="tbodyTarjetas" style={{display: 'none',}}>
                  {tarjetas.map((item) =>(
                    <tr>
                      <td scope="col">{item.data.tarjetaCodigo}</td>
                      <td scope="col">
                        <NombreProducto
                          idProducto={item.data.idProducto}
                        />
                      </td>
                      <td scope="col">
                        <button onClick={() => {
                          CambioMostrarModalPreguntaCredencial(true);
                          CambioIdCredencial(item.id);
                        }} class="btn btn-danger">
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">

              <button style={{display: 'none',}} onClick={verMas} class="btn btn-primary" id="verMas">
                Ver más
              </button><br/><br/>

            </div>
          </div>

        </div>
      </div>
      <Modal mostrar={mostrarModalPreguntaCredencial}>
          <div style={{padding: 20,}}>
            <h5 class="tituloIniciarSesion sinMargenes">¿Desea eliminar esta credencial?</h5>
              <div style={{textAlign: 'center',}}>
                <button class="btn btn-primary" onClick={removeCredencial}>Si</button>
                <button onClick={() => {
                  CambioMostrarModalPreguntaCredencial(false);
                  CambioIdCredencial(false);
                }} style={{margin: 5,}} class="btn btn-danger">No</button>
              </div>
          </div>
      </Modal>
    </AnimatedPage>
  );
}

export default AdminCredencialesScreen;