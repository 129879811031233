import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde, actualizarEmail, auth, actualizarPassword} from '../conex.js';
import {iniciarSesion} from '../auth/auth';

export async function actulizarFotoPerfil(uid, laFoto){

	const storageRefLogo = referencia(almacenamiento, 'assets/usuarios/'+uid+'/'+uid);

	await subirArchivo(storageRefLogo, laFoto);

	let foto = await obtenerURL(storageRefLogo);

	const washingtonRef = documento(db, "usuarios", uid)

	await actualizarDoc(washingtonRef, {
	  foto: foto,
	});

	const docRef = documento(db, "usuarios", uid);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	}else{
		return false;
	}
}

export async function actualizarDatos(nombre, email, password, usuario){

	if (email != usuario.email) {

		let confirmarSesion = await iniciarSesion(usuario.email, password);

		if (confirmarSesion != false) {

			let actualizando = await actualizarEmail(auth.currentUser, email).then(() => {
			  return true;
			}).catch((error) => {
			  return false;
			});

			if (actualizando) {
				const ref = documento(db, "usuarios", confirmarSesion.uid);

				await actualizarDoc(ref, {
				  email: email,
				  nombre: nombre,
				});

				const docRef = documento(db, "usuarios", confirmarSesion.uid);
				const docSnap = await leerDatos(docRef);
				if (docSnap.exists()) {
					return docSnap.data();
				}else{
					return false;
				}

			}else{
				return false;
			}
		}else{
			return false;
		}
	}else{

		const ref = documento(db, "usuarios", usuario.uid);

		await actualizarDoc(ref, {
			email: email,
			nombre: nombre,
		});

		const docRef = documento(db, "usuarios", usuario.uid);
		const docSnap = await leerDatos(docRef);
		if (docSnap.exists()) {
			return docSnap.data();
		}else{
			return false;
		}
	}
}

export async function guardarNuevaPassword(actualPassword, nuevaPassword){
	let usuario = await localStorage.getItem('usuarioVenetGoPlay');
	if (usuario != null) {
		let dataUser = JSON.parse(usuario);
		let confirmarSesion = await iniciarSesion(dataUser.email, actualPassword);

		if (confirmarSesion != false) {
			const user = auth.currentUser;
			let actualizacion = await actualizarPassword(user, nuevaPassword).then(() => {
			  return true;
			}).catch((error) => {
			  return false;
			});
			return actualizacion;
		}else{
			return false;
		}
	}else{
		return false;
	}
}