import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';
import {consultarCreditos} from '../recargar/recargar';
import {consultarCredenciales, eliminarCredencial, terminarEliminarPerfil, consultarPerfiles} from '../credenciales/credenciales';
import {meses} from '../../global/datosCalendario';

export async function guardarProducto(id, data) {
	
	const storageRefLogo = referencia(almacenamiento, 'assets/productos/'+id+'/'+id);

	await subirArchivo(storageRefLogo, data.foto);

	let foto = await obtenerURL(storageRefLogo);

	await set(documento(db, "productos", id), {
		activo: data.activo,
        nombre: data.nombre,
        precio: data.precio,
        precioCuentaCompleta: data.precioCuentaCompleta,
        precioRevendedor: data.precioRevendedores,
        precioCuentaCompletaRevendedor: data.precioCuentaCompletaRevendedores,
        categoria: data.categoria,
        color: data.color,
        tipo: data.tipo,
        region: data.region,
        foto: foto,
	});

	return true;
}

export async function consultarProductos(){

	let productos = [];
	const q = buscar(coleccion(db, "productos"), donde("nombre", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  productos.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	return productos;
}

export async function eliminarProducto(id){

	await eliminarDoc(documento(db, "productos", id));

	return true;
}

export async function procesarCompra(usuario, producto, tiempo, pantalla, tipoCuenta){

	let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    let id = "";
      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	const datosUsuario = await consultarCreditos(usuario.uid);

	if (datosUsuario != false) {

		let creditos = datosUsuario.creditos;
		let precio = 0;

		if (pantalla == 'revendedor') {

			if (producto.data.tipo == 'tarjeta') {

				precio = parseInt(producto.data.precioRevendedor);
				tiempo = '1 Mes';
			}else if (producto.data.tipo == 'cuenta') {

				if (tipoCuenta == '1 Pantalla') {

					precio = parseInt(producto.data.precioRevendedor);
				}else if (tipoCuenta == 'Cuenta Completa') {

					precio = parseInt(producto.data.precioCuentaCompletaRevendedor);
				}
			}
		}else{

			if (producto.data.tipo == 'tarjeta') {

				precio = parseInt(producto.data.precio);
				tiempo = '1 Mes';
			}else if (producto.data.tipo == 'cuenta') {

				if (tipoCuenta == '1 Pantalla') {

					precio = parseInt(producto.data.precio);
				}else if (tipoCuenta == 'Cuenta Completa') {

					precio = parseInt(producto.data.precioCuentaCompleta);
				}
			}
		}

		if (precio > creditos) {

			return {
				resultado: false,
				mensaje: 'No tienes creditos suficientes para comprar',
			};
		}else{

			let resta = creditos - precio;
			let credenciales = [];

			let lasCredenciales = await consultarCredenciales();

			lasCredenciales.forEach(element =>{

				if (element.data.idProducto == producto.id && element.data.tipo == producto.data.tipo) {

		           	credenciales.push(element);
		        }
			})

			if (credenciales.length > 0) {

				if (producto.data.tipo == 'cuenta') {

					let credencial = false;
					let perfiles = [];

					if (tipoCuenta == 'Cuenta Completa') {

						credenciales.forEach(element =>{
							if (element.data.cuentaCompleta == true && element.data.cuentaCompleta != undefined) {

								credencial = element;
								perfiles.push(0);
							}
						})
					}else{

						credencial = credenciales[0];
						perfiles = await consultarPerfiles(credencial.id);
					}

					if (credencial != false) {

						let date = new Date();

						let dia = date.getDate();
						let mes = meses[date.getMonth()].numeroMes;
						let ano = date.getFullYear();

						let fechaPago = ano+'-'+mes+'-'+dia;

						let diasMes = meses[date.getMonth()].dias;

						let vencimiento = '';

						if (tiempo == '10 Días' || tiempo == '15 Días') {

							let calculo = 0;
							let sumaDias = '';

							if (tiempo == '10 Días') {

								calculo = 10;
								sumaDias = dia + 10;
							}else if (tiempo == '15 Días') {

								calculo = 15;
								sumaDias = dia + 15;
							}

							if (sumaDias > diasMes) {

								let sumaMes = date.getMonth() + 1;

								if (sumaMes > 11) {

									mes = meses[0].numeroMes;

									let sumaAno = ano + 1;

									ano = sumaMes;
								}else{

									mes = meses[sumaMes].numeroMes;
								}

								for (let i = 0; i <= calculo; i++) {
					
									if (dia > diasMes) {

										i = 1;
									}

									dia++; 
								}

								vencimiento = ano+'-'+mes+'-'+dia;
							}else{

								for (let i = 0; i <= calculo; i++) {
					
									if (dia > diasMes) {

										i = 1;
									}

									dia++; 
								}

								vencimiento = ano+'-'+mes+'-'+dia;
							}

							
						}else if (tiempo == '1 Mes') {

							let sumaMes = date.getMonth() + 1;

							if (sumaMes > 11) {

								mes = meses[0].numeroMes;

								let sumaAno = ano + 1;

								ano = sumaMes;
							}else{

								mes = meses[sumaMes].numeroMes;
							}

							if (sumaMes == 1 && dia == 30 || dia == 31) {

								dia = 28;
							}

							vencimiento = ano+'-'+mes+'-'+dia;
						}

						await set(documento(db, "compras", id), {
							uid: usuario.uid,
							datosCompra:{
								producto: producto,
								credencial: credencial,
								perfil: perfiles[0],
								tiempo: tiempo,
								vencimiento: vencimiento,
								tipoCuenta: tipoCuenta,
							},
							tipo: 'producto',
							metodoDePago: 'creditos',
							idOrden: id,
							fecha: fechaPago,
						});

						const washingtonRef = documento(db, "usuarios", usuario.uid);

						await actualizarDoc(washingtonRef, {
						  creditos: resta,
						});

						if (tipoCuenta == 'Cuenta Completa') {

							await eliminarCredencial(credencial.id);

							return {
								resultado: true,
								mensaje: '',
							};
						}else{

							if (perfiles.length == 1) {

								await eliminarCredencial(credencial.id);
								await terminarEliminarPerfil(perfiles[0].id);

								return {
									resultado: true,
									mensaje: '',
								};
							}else{

								await terminarEliminarPerfil(perfiles[0].id);

								return {
									resultado: true,
									mensaje: '',
								};
							}
						}
					}else{

						return {
							resultado: false,
							mensaje: 'No hay disponibilidad en el stock.',
						};
					}
				}else if (producto.data.tipo == 'tarjeta') {

					let date = new Date();

					let dia = date.getDate();
					let mes = meses[date.getMonth()].numeroMes;
					let ano = date.getFullYear();

					let fechaPago = ano+'-'+mes+'-'+dia;

					let diasMes = meses[date.getMonth()].dias;

					let vencimiento = '';

					if (tiempo == '10 Días' || tiempo == '15 Días') {

							let calculo = 0;
							let sumaDias = '';

							if (tiempo == '10 Días') {

								calculo = 10;
								sumaDias = dia + 10;
							}else if (tiempo == '15 Días') {

								calculo = 15;
								sumaDias = dia + 15;
							}

							if (sumaDias > diasMes) {

								let sumaMes = date.getMonth() + 1;

								if (sumaMes > 11) {

									mes = meses[0].numeroMes;

									let sumaAno = ano + 1;

									ano = sumaMes;
								}else{

									mes = meses[sumaMes].numeroMes;
								}

								for (let i = dia; i <= calculo; i++) {
									
									if (i > diasMes) {

										i = 1;
									}

									dia = i;
								}

								vencimiento = ano+'-'+mes+'-'+dia;
							}else{

								for (let i = dia; i <= calculo; i++) {
									
									if (i > diasMes) {

										i = 1;
									}

									dia = i;
								}

								vencimiento = ano+'-'+mes+'-'+dia;
							}

							
					}else if (tiempo == '1 Mes') {

							let sumaMes = date.getMonth() + 1;

							if (sumaMes > 11) {

								mes = meses[0].numeroMes;

								let sumaAno = ano + 1;

								ano = sumaMes;
							}else{

								mes = meses[sumaMes].numeroMes;
							}

							if (sumaMes == 1 && dia == 30 && dia == 31) {

								dia = 28;
							}

							vencimiento = ano+'-'+mes+'-'+dia;
					}

					await set(documento(db, "compras", id), {
						uid: usuario.uid,
						datosCompra:{
							producto: producto,
							credencial: credenciales[0],
							tiempo: tiempo,
							vencimiento: vencimiento,
							tipoCuenta: tipoCuenta,
						},
						tipo: 'producto',
						metodoDePago: 'creditos',
						idOrden: id,
						fecha: fechaPago,
					});

					const washingtonRef = documento(db, "usuarios", usuario.uid);

					await actualizarDoc(washingtonRef, {
						creditos: resta,
					});

					await eliminarCredencial(credenciales[0].id);

					return {
						resultado: true,
						mensaje: '',
					};
				}
			}else{

				return {
					resultado: false,
					mensaje: 'No hay disponibilidad en el stock.',
				};
			}
		}
	}else {

		return false;
	}
}

export async function consultarCompras(idProducto){

	let compras = [];
	let comprasFinales = [];

	const q = buscar(coleccion(db, "compras"), donde("tipo", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  	
	  	if (doc.data().datosCompra.producto != undefined) {

	  		compras.push(doc.data().datosCompra.producto);
	  	}
	});

	compras.forEach(element =>{

		if (element.id == idProducto) {

			comprasFinales.push(element);
		}
	})

	return comprasFinales;
}