import React, { useState, useEffect } from 'react';
import './home-styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {Link} from 'react-router-dom';

export function Deslizador({
	galeria,
	mostrar
}){

	if (mostrar) {

		return(
			<Carousel
				showThumbs={false}
				showArrows={false}
				autoPlay={true}
				interval={3000}
				autoFocus={true}
				infiniteLoop={true}
			>
			    {galeria.map((item) =>(
			     	<img
			      		src={item.data.foto}
			      		style={{width: '100%',}}
			     	/>
			    ))}
			</Carousel>
		);
	}else{

		return(
			<></>
		);
	}
}

export function DeslizadorCategias({
	categorias
}){

	return(
		<>	
			<div class="productosHome">
				{categorias.map((item) =>(
					<Link class="urlCat" to={"/busqueda/categorias/"+item.id}>
						<img
							src={item.data.foto}
							class="vistaImgCategoria"
						/>
					</Link>
				))}
			</div>
		</>
	);
}