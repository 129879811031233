import React, { useState, useEffect } from 'react';
import './login-styles.css';
import {useParams} from 'react-router-dom';
import {validarUsuario} from '../../../global/validarUsuario';
import {MenuApp} from '../../../components/menu/menu';
import {Modal} from '../../../components/modals/modals';
import {userPlus, user, mail, passwordImg, eye, eyeNo, userPlusBlanco, userLock, enter} from '../../../global/imgs';
import {resgistrarUsuario, iniciarSesion, recuperarPassword} from '../../../backend/auth/auth';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import {AnimatedPage} from '../../../components/animacion/animacion';

function LoginScreen(){

	const routeParams = useParams();
  	const form = routeParams.form;

	const [pantalla, CambioPantalla] = useState('');
	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [mostrarPassword, CambioMostrarPassword] = useState(eye);
	const [nombre, CambioNombre] = useState('');
	const [email, CambioEmail] = useState('');
	const [password, CambioPassword] = useState('');
	const [codigoArea, CambioCodigoArea] = useState('');
	const [numero, CambioNumero] = useState('')

	const [modalPassword, CambioModalPassword] = useState(false);
	const [emailRecuperar, CambioEmailRecuperar] = useState('');

	useEffect(() =>{

		if (form == 'registro') {

			CambioPantalla('registro');
		}else if (form == 'ingresar') {

			CambioPantalla('ingresar');
		}

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/cliente';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin';
			}
		}
	}

	const verPassword = async function(){

		if (mostrarPassword == eye) {

			document.getElementById('password').setAttribute("type", "text");
			CambioMostrarPassword(eyeNo);
		}else if (mostrarPassword == eyeNo) {

			document.getElementById('password').setAttribute("type", "password");
			CambioMostrarPassword(eye);
		}
	}

	const procesarRegistro = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let telefono = '';

	    if (codigoArea != '' || numero != '') {

	    	if (isNaN(numero) || isNaN(codigoArea)) {
	    		console.log('nada');
	    	}else{

	    		telefono = "+"+codigoArea+numero;
	    	} 
	    }

	    let data ={ 
	      nombre: nombre,
	      email: email,
	      password: password,
	      telefono: telefono,
	      rol: 'cliente',
	    }; console.log(data);
	    if (data.nombre === '' || data.email === '' || data.password === '' || data.telefono === '') {
	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{
	      resgistrarUsuario(data).then(response =>{
	        if (response != false) {
	          terminar(response);
	        }else{
	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      });
	    }
	}

	const terminar = async function(respuesta){

		if (respuesta != false) {

			await localStorage.setItem('usuarioVenetGoPlay', JSON.stringify(respuesta));

			if (respuesta.rol == 'admin' || respuesta.rol == 'subAdmin') {

				window.location.href = '/admin';
			}else if (respuesta.rol == 'cliente') {

				window.location.href = '/';
			}
		}else{

			Swal.fire({
		        icon: 'error',
		        title: 'Lo sentimos pero hubo un error al autenticar sus datos.',
		    });
		}
	}

	const procesarLogin = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    if (email === '' || password === '') {
	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{
	      iniciarSesion(email, password).then(response =>{
	        if (response != false) {
	          terminar(response);
	        }else{
	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      })
	    }
	}

	const procesarRecuperacion = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    if (emailRecuperar === '') {
	      Swal.fire({
	        title: 'Complete todos los campos para continuar.',
	        icon: 'info',
	      });
	    }else{
	      recuperarPassword(emailRecuperar).then(response =>{
	        if (response) {
	          Swal.fire({
	            title: 'Se ha enviado un correo electronico.',
	            icon: 'success',
	          });
	          CambioModalPassword(false);
	          CambioEmailRecuperar('');
	        }
	      })
	    }
	}

	if (pantalla == 'registro') {

		return(
			<>
				<MenuApp/>
				<div class="containerAuth">
					<div class="container">
						<div class="row">
							<div class="col-sm">
								<AnimatedPage duracion={0.5}>
									<div class="form">

										<div class="headerFormLogin">
											<img
												src={userPlus}
												class="userPlus"
											/>
											<h4 class="tituloHeaderLogin">REGISTRAR</h4>
										</div>

										<div class="mb-3 containerInput">
											<img
												src={user}
												class="imgLabel"
											/>
										  	<input onChange={() => CambioNombre(document.getElementById('nombre').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="nombre" placeholder="Nombre *" />
										</div>

										<div class="mb-3 containerInput">
											<img
												src={mail}
												class="imgLabel"
											/>
										  	<input onChange={() => CambioEmail(document.getElementById('email').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="email" placeholder="Correo *" />
										</div>

										<div class="mb-3 containerInput">
											<img
												src={passwordImg}
												class="imgLabel"
											/>
										  	<input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña *" />
											<img
												src={mostrarPassword}
												class="imgLabel"
												onClick={verPassword}
											/>
										</div>

										<div class="inputTelefono">

											<div style={{width: '20%',}} class="mb-3 containerInput">
											  	<input onChange={() => CambioCodigoArea(document.getElementById('codigoArea').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="codigoArea" placeholder="+00 *" />
											</div>

											<div style={{width: '75%',}} class="mb-3 containerInput">
											  	<input onChange={() => CambioNumero(document.getElementById('numero').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="numero" placeholder="Numero de Whatsapp *" />
											</div>

										</div>

										<span style={{color: '#3ad32b', fontSize: 10,}}>
											Por favor use su numero de whatsapp, le enviaremos información importante a este numero. Gracias.<br/>
											En el campo "+00" coloque el código de area coloque un "+" y luego el código, por ejemplo: "+58".
										</span>

										<button onClick={procesarRegistro} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
											<img
												src={userPlusBlanco}
												class="imgBoton"
											/>
											REGISTRAR
										</button>

										<p class="textoOpcionesLogin">Ya tienes una cuenta? <Link to='/auth/ingresar' class="opcionLogin">Ingresar</Link></p>
									</div>
								</AnimatedPage>
							</div>
						</div>
					</div>
				</div>
			</>	
		);
	}else if (pantalla == 'ingresar') {

		return(
			<>
				<MenuApp/>
				<div class="containerAuth">
					<div class="container">
						<div class="row">
							<div class="col-sm">

								<AnimatedPage duracion={0.5}>
									<div class="form">

										<div class="headerFormLogin">
											<img
												src={userLock}
												class="userPlus"
											/>
											<h4 class="tituloHeaderLogin">INGRESAR</h4>
										</div>

										<div class="mb-3 containerInput">
											<img
												src={mail}
												class="imgLabel"
											/>
										  	<input onChange={() => CambioEmail(document.getElementById('email').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="email" placeholder="Correo *" />
										</div>

										<div class="mb-3 containerInput">
											<img
												src={passwordImg}
												class="imgLabel"
											/>
										  	<input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña *" />
											<img
												src={mostrarPassword}
												class="imgLabel"
												onClick={verPassword}
											/>
										</div>

										<button onClick={procesarLogin} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
											<img
												src={enter}
												class="imgBoton"
											/>
											INGRESAR
										</button>

										<p onClick={() => CambioModalPassword(true)} class="textoOpcionesLogin"><span class="opcionLogin">¿Olvidaste tu contraseña?</span></p>

										<p class="textoOpcionesLogin">No estás registrado? <Link to='/auth/registro' class="opcionLogin">Registrarse</Link></p>
									</div>
								</AnimatedPage>

							</div>
						</div>
					</div>
				</div>

				<Modal mostrar={modalPassword}>
			        <p onClick={() => CambioModalPassword(false)} class="equix">X</p>
			        <div class="containerRecuperar">
			            <h5 class="tituloIniciarSesion sinMargenes">RECUPERAR CONTRASEÑA</h5>
			            <div class="mb-3 containerInput">
							<img
								src={mail}
								class="imgLabel"
							/>
							<input onChange={() => CambioEmailRecuperar(document.getElementById('emailRecuperar').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="emailRecuperar" placeholder="Correo *" />
						</div>
			            <button onClick={() => procesarRecuperacion()} class="btn btn-info" style={{marginTop: 20,}}>ENVIAR</button>
			        </div>
			    </Modal>
			</>
		);
	}else{

		return(
			<>
				<MenuApp/>
				<div class="containerAuth">
					
				</div>
			</>
		);
	}
}

export default LoginScreen;