import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function consultarUsuarios(){

	let usuarios = [];
	const q = buscar(coleccion(db, "usuarios"), donde("nombre", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  usuarios.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	return usuarios;
}

export async function actualizarEstadoUsuario(id, estadoUsuario){

	const washingtonRef = documento(db, "usuarios", id);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  activo: estadoUsuario,
	});

	return true;
}

export async function actualizarRevendedor(id, estadoUsuario){

	const washingtonRef = documento(db, "usuarios", id);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  revendedor: estadoUsuario,
	});

	return true;
}