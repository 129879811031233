import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function guardarCategoria(id, data) {
	
	const storageRefLogo = referencia(almacenamiento, 'assets/categorias/'+id+'/'+id);

	await subirArchivo(storageRefLogo, data.foto);

	let foto = await obtenerURL(storageRefLogo);

	await set(documento(db, "categorias", id), {
		nombre: data.nombre,
		foto: foto,
	});

	return true;
}

export async function consultarCategorias(){

	let categorias = [];
	const q = buscar(coleccion(db, "categorias"), donde("nombre", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  categorias.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	return categorias;
}