import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function consultarPedidos(uid) {
	
	let compras = [];
	let pedidos = [];

	const q = buscar(coleccion(db, "compras"), donde("uid", "==", uid));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  compras.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	compras.sort(function(a, b) { 
		a = a.data.fecha; 
		b = b.data.fecha; 
		return a>b ? -1 : a<b ? 1 : 0; 
	});

	compras.forEach(element =>{

		if (element.data.tipo == 'producto') {

			pedidos.push(element);
		}
	})

	return pedidos;
}