import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc} from '../conex.js';

export async function resgistrarUsuario(datos){
	let registro = await crearUsuario(auth, datos.email, datos.password).then((userCredential) => {
	    // Signed in
	    const user = userCredential.user;
	    // ...
	    return user;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    // ..
	    return false;
	});

	if (registro != false) {

		await set(documento(db, "usuarios", registro.uid), {
			activo: true,
			nombre: datos.nombre,
			email: datos.email,
			rol: datos.rol,
			foto: 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fuser%2Fimages.jpeg?alt=media&token=e782f4b3-7b5c-4335-8e6d-d20d489d854f',
			uid: registro.uid,
			creditos: 0,
			revendedor: false,
			telefono: datos.telefono,
		});

		return {
			activo: true,
			nombre: datos.nombre,
			email: datos.email,
			rol: datos.rol,
			foto: 'https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fuser%2Fimages.jpeg?alt=media&token=e782f4b3-7b5c-4335-8e6d-d20d489d854f',
			uid: registro.uid,
			creditos: 0,
			revendedor: false,
			telefono: datos.telefono,
		};
	}else{
		return false;
	}
}

export async function iniciarSesion(email, password){
	let usuario = await sesionUsuario(auth, email, password).then((userCredential) => {
	    // Signed in
	    const user = userCredential.user;
	    // ...
	    return user;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    return false;
	});
	if (usuario != false) {
		const docRef = documento(db, "usuarios", usuario.uid);
		const docSnap = await leerDatos(docRef);
		if (docSnap.exists()) {
			return docSnap.data();
		}else{
			return false;
		}
	}else{
		return false;
	}
}

export async function recuperarPassword(email){
	let actualizando = await cambiarPassword(auth, email).then(() => {
	    // Password reset email sent!
	    // ..
	    return true;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    // ..
	    return false;
	});

	return actualizando;
}

export async function guardarNuevaPassword(actualPassword, nuevaPassword){
	let usuario = await localStorage.getItem('usuarioRevirefer');
	if (usuario != null) {
		let dataUser = JSON.parse(usuario);
		let confirmarSesion = await iniciarSesion(dataUser.email, actualPassword);

		if (confirmarSesion != false) {
			const user = auth.currentUser;
			let actualizacion = await actualizarPassword(user, nuevaPassword).then(() => {
			  return true;
			}).catch((error) => {
			  return false;
			});
			return actualizacion;
		}else{
			return false;
		}
	}else{
		return false;
	}
}

export async function guardarNuevoEmail(actualEmail, nuevoEmail, passwordActual){
	let confirmarSesion = await iniciarSesion(actualEmail, passwordActual);

	if (confirmarSesion != false) {
		let actualizando = await actualizarEmail(auth.currentUser, nuevoEmail).then(() => {
		  return true;
		}).catch((error) => {
		  return false;
		});

		if (actualizando) {
			const ref = documento(db, "usuarios", confirmarSesion.uid);

			await actualizarDoc(ref, {
			  email: nuevoEmail
			});

			const docRef = documento(db, "usuarios", confirmarSesion.uid);
			const docSnap = await leerDatos(docRef);
			if (docSnap.exists()) {
				return docSnap.data();
			}else{
				return false;
			}

		}else{
			return false;
		}
	}else{
		return false;
	}
}