import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';

export async function guardarImagen(id, foto){

	const storageRefLogo = referencia(almacenamiento, 'assets/galeria/'+id+'/'+id);

	await subirArchivo(storageRefLogo, foto);

	let url = await obtenerURL(storageRefLogo);

	await set(documento(db, "galeria", id), {
		foto: url,
	});

	return true;
}

export async function consultarGaleria(){

	let galeria = [];
	const q = buscar(coleccion(db, "galeria"), donde("foto", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  galeria.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	return galeria;
}

export async function eliminarFoto(id){

	await eliminarDoc(documento(db, "galeria", id));

	return true;
}

export async function guardarPublicidadRevendedores(id, foto){

	const storageRefLogo = referencia(almacenamiento, 'assets/publicidad/revendedores/foto');

	await subirArchivo(storageRefLogo, foto);

	let url = await obtenerURL(storageRefLogo);

	await set(documento(db, "publicidad", 'revendedores'), {
		foto: url,
	});

	return true;
}

export async function verPublicidad(){

	const docRef = documento(db, "publicidad", "revendedores");
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return docSnap.data();
	}else{
		return false;
	}
}

export async function eliminarPublicidad(){

	await eliminarDoc(documento(db, "publicidad", "revendedores"));

	return true;
}