import { async } from '@firebase/util';
import {almacenamiento, referencia, subirArchivo, obtenerURL, db, obtenerDocs, documento, coleccion, agregarDoc, set, actualizarDoc, eliminarDoc, leerDatos, buscar, donde} from '../conex.js';
import {meses} from '../../global/datosCalendario';

export async function asignarCreditos(uid, cantidad) {

	let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    let id = "";
        for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));
	
	const docRef = documento(db, "usuarios", uid);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {

		let date = new Date();

		let dia = date.getDate();
		let mes = meses[date.getMonth()].numeroMes;
		let ano = date.getFullYear();

		let fecha = ano+'-'+mes+'-'+dia;

		let creditos = docSnap.data().creditos;

		await set(documento(db, "compras", id), {
			uid: uid,
			datosCompra:{
				creditos: cantidad,
			},
			tipo: 'creditos',
			metodoDePago: 'bancario',
			idOrden: id,
			fecha: fecha,
		});

		let suma = parseInt(creditos) + parseInt(cantidad);

		const washingtonRef = documento(db, "usuarios", uid);

		await actualizarDoc(washingtonRef, {
		  creditos: suma,
		});

		return true;
	}else{
		return false;
	}
}

export async function consultarCompras(uid){

	let compras = [];
	const q = buscar(coleccion(db, "compras"), donde("uid", "==", uid));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
	  compras.push({
	  	id: doc.id,
	  	data: doc.data(),
	  })
	});

	compras.sort(function(a, b) { 
		a = a.data.fecha; 
		b = b.data.fecha; 
		return a>b ? -1 : a<b ? 1 : 0; 
	});

	return compras;
}

export async function consultarCreditos(uid){

	const docRef = documento(db, "usuarios", uid);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {

		return docSnap.data();
	}else{

		return false;
	}
}