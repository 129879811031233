export async function validarUsuario() {
	
	let usuario = await localStorage.getItem('usuarioVenetGoPlay');

	if (usuario != null) {

		let dataUser = JSON.parse(usuario);

		return dataUser;
	}else{

		return false;
	}
}