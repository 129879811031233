import React, { useState, useEffect } from 'react';
import './estadoUsuario-styles.css';
import {logoMenu, ticket, userImg, pedidosImg, recargasImg, enter} from '../../global/imgs';
import {validarUsuario} from '../../global/validarUsuario';
import {consultarCreditos} from '../../backend/recargar/recargar';
import {Link} from 'react-router-dom';

export function EstadoUsuario() {
	
	const [usuario, CambioUsuario] = useState(false);
	const [imgUsuario, CambioImgUsuario] = useState('https://firebasestorage.googleapis.com/v0/b/venet-go-play.appspot.com/o/assets%2Fcore%2Fuser%2Fimages.jpeg?alt=media&token=e782f4b3-7b5c-4335-8e6d-d20d489d854f');
	const [opcionesUsuario, CambioOpcionesUsuario] = useState(false);
	const [creditos, CambioCreditos] = useState(0);

	useEffect(() =>{

		if (usuario == false) {
			ponerUsuario();
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		CambioUsuario(elUsuario);
		CambioImgUsuario(elUsuario.foto);

		window.setTimeout(quitarOpciones, 1000, elUsuario);

		if (elUsuario != false) {

			consultarCreditos(elUsuario.uid).then(response =>{

				if (response != false) {

					CambioCreditos(response.creditos);
				}
			})	
		}
	}

	const quitarOpciones = async function(unUsuario){

		if (unUsuario.rol == 'admin' || unUsuario.rol == 'subAdmin') { 

			document.getElementById('opcion1').setAttribute('style', 'display: none;');
			document.getElementById('opcion2').setAttribute('style', 'display: none;');
			document.getElementById('opcion3').setAttribute('style', 'display: none;');
			document.getElementById('opcionesUsuario').classList.add('heightAuto');
		}
	}

	const cerrarSesion = async function(){

		await localStorage.clear();

		window.location.href = '/';
	}

	const verOpcionesUsuario = async function(){

		if (opcionesUsuario) {

			document.getElementById('opcionesUsuario').setAttribute('style', '');
			CambioOpcionesUsuario(false);
		}else{

			document.getElementById('opcionesUsuario').setAttribute('style', 'display: block;');
			CambioOpcionesUsuario(true);
		}
	}

	if (usuario != false) {

		return(
			<div class="menuUsuario">
				<span class="cantidadSaldoMenu">
					<img
						src={ticket}
						class="ticket"
					/>
					<span>$ {creditos}</span>
				</span>
				<div>
					<img
						src={imgUsuario}
						class="imgUsuario"
						onClick={verOpcionesUsuario}
					/>
					<div id="opcionesUsuario" class="opcionesUsuario">

						<Link to='/cliente' id="opcion1" class="opcionUsuario">
							<div class="containerImgOpcionUsuario">
								<img
									src={userImg}
									class="imgOpcionUsuario"
								/>
							</div>
							<span class="textoOpcionUsuario">Mi cuenta</span>
						</Link>

						<Link to='/cliente/pedidos' id="opcion2" class="opcionUsuario">
							<div class="containerImgOpcionUsuario">
								<img
									src={pedidosImg}
									class="imgOpcionUsuario"
								/>
							</div>
							<span class="textoOpcionUsuario">Mis pedidos</span>
						</Link>

						<Link to='/cliente/recargar' id="opcion3" class="opcionUsuario">
							<div class="containerImgOpcionUsuario">
								<img
									src={recargasImg}
									class="imgOpcionUsuario"
								/>
							</div>
							<span class="textoOpcionUsuario">Recargar</span>
						</Link>

						<div onClick={cerrarSesion} class="opcionUsuario">
							<div class="containerImgOpcionUsuario" style={{background: '#b91c1c',}}>
								<img
									src={enter}
									class="imgOpcionUsuario"
								/>
							</div>
							<span class="textoOpcionUsuario">Salir</span>
						</div>

					</div>
				</div>
			</div>
		);
	}else{

		return(
			<div class="botonesAuth">
				<Link to='/auth/registro' style={{background: '#b91c1c', marginRight: 10,}} class="btn btn-danger">RECARGAR CRÉDITOS</Link>
				<Link to='/auth/ingresar' style={{background: '#15803d', marginRight: 10,}} class="btn btn-success">INGRESAR</Link>
				<Link to='/auth/registro' style={{background: '#1d4ed8'}} class="btn btn-primary">REGISTRARSE</Link>
			</div>
		);
	}
}