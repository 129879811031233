import React, { useState, useEffect } from 'react';
import './footer-styles.css';
import {logoMenu, instagram} from '../../global/imgs';
import {Link} from 'react-router-dom';

export function Footer(){

	let fecha = new Date();

	return(
		<div class="footer">

			<div class="filaFooter">
				<Link to='/'>
					<img
						class="logoMenu"
						src={logoMenu}
					/>
				</Link>

				<img
					src={instagram}
					style={{width: 30, cursor: 'pointer',}}
					onClick={() => window.location.href = 'https://www.instagram.com/venetgoplayca/'}
				/>

			</div><br/>

			<div class="hr"></div><br/>

			<div class="filaFooter" style={{justifyContent: 'center',}}>

				<span class="textoDerechos">© {fecha.getFullYear()} Venet Go Play. All Rights Reserved.</span>

			</div><br/>

		</div>
	);
}