import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword, updateEmail } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, addDoc, updateDoc, collection, getDocs, deleteDoc, query, where, } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA9DBqtSkVdgyg4-yesIGaDp2T9QafOePs",
  authDomain: "venet-go-play.firebaseapp.com",
  projectId: "venet-go-play",
  storageBucket: "venet-go-play.appspot.com",
  messagingSenderId: "350207189544",
  appId: "1:350207189544:web:13bf293f79b535119f8b1d",
  measurementId: "G-P30J37SD0Q"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth();
export const crearUsuario = createUserWithEmailAndPassword;
export const db = getFirestore(app);
export const obtenerDocs = getDocs;
export const documento = doc;
export const coleccion = collection;
export const agregarDoc = addDoc;
export const set = setDoc;
export const actualizarDoc = updateDoc;
export const eliminarDoc = deleteDoc;
export const sesionUsuario = signInWithEmailAndPassword;
export const leerDatos = getDoc;
export const cambiarPassword = sendPasswordResetEmail;
export const actualizarEmail = updateEmail;
export const actualizarPassword = updatePassword;
export const almacenamiento = getStorage(app);
export const referencia = ref;
export const subirArchivo = uploadBytes;
export const obtenerURL = getDownloadURL;
export const buscar = query;
export const donde = where;