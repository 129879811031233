import React, { useState, useEffect } from 'react';
import './usuarios-styles.css';
import {MenuApp, MenuAdmin} from '../../../components/menu/menu';
import {BotonAccion} from '../../../components/usuarios/usuarios';
import {validarUsuario} from '../../../global/validarUsuario';
import {userImg, userPlus, user, mail, passwordImg, eye, eyeNo, userPlusBlanco, userLock, enter, recargasImg} from '../../../global/imgs';
import {resgistrarUsuario} from '../../../backend/auth/auth';
import {consultarUsuarios, actualizarEstadoUsuario, actualizarRevendedor} from '../../../backend/usuarios/usuarios';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {asignarCreditos} from '../../../backend/recargar/recargar';
import {AnimatedPage} from '../../../components/animacion/animacion';

function AdminUsersScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [nombre, CambioNombre] = useState('');
	const [email, CambioEmail] = useState('');
	const [password, CambioPassword] = useState('');
	const [mostrarPassword, CambioMostrarPassword] = useState(eye);
	const [usuariosBuscados, CambioUsuariosBuscados] = useState(false);
	const [limiteUsuarios, CambioLimiteUsuarios] = useState(20);
	const [usuarios, CambioUsuarios] = useState([]);
	const [usuariosCliente, CambioUsuariosCliente] = useState([]);
	const [usuariosAdmin, CambioUsuariosAdmin] = useState([]);
	const [textoModal, CambioTextoModal] = useState('');
	const [mostrarModalPreguntaUsuario, CambioMostrarModalPreguntaUsuario] = useState(false);
	const [idUsuario, CambioIdUsuario] = useState(false);
	const [estadoUsuario, CambioEstadoUsuario] = useState(true);
	const [modalAsignarCreditos, CambioModalAsignarCreditos] = useState(false);
	const [cantidad, CambioCantidad] = useState('');
	const [mostrarModalPreguntaRevendedor, CambioMostrarModalPreguntaRevendedor] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (usuariosBuscados == false) {

			consultarUsuarios().then(response =>{ console.log(response);

				let porTipo = document.getElementById('tipoMostrar').value;
				let losUsuariosCliente = [];
				let losUsuariosAdmin = [];

				let cero = 0;

	            response.forEach(element =>{

	              if (element.data.rol == 'cliente') {

	                if (cero <= limiteUsuarios) {
	                  losUsuariosCliente.push(element);
	                }
	              }else if (element.data.rol == 'subAdmin') {

	                if (cero <= limiteUsuarios) {
	                  losUsuariosAdmin.push(element);
	                }
	              }

	              cero++;
	            });

	            if (response.length > 20) {
	              document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
	            }

	            CambioUsuarios(response);
	            CambioUsuariosCliente(losUsuariosCliente);
	            CambioUsuariosAdmin(losUsuariosAdmin);
			})

			CambioUsuariosBuscados(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'admin') {

			}else{

				window.location.href = '/';
			}
		}else{

			window.location.href = '/';
		}
	}

	const verPassword = async function(){

		if (mostrarPassword == eye) {

			document.getElementById('password').setAttribute("type", "text");
			CambioMostrarPassword(eyeNo);
		}else if (mostrarPassword == eyeNo) {

			document.getElementById('password').setAttribute("type", "password");
			CambioMostrarPassword(eye);
		}
	}

	const procesarRegistro = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let data ={ 
	      nombre: nombre,
	      email: email,
	      password: password,
	      rol: 'subAdmin',
	    }; console.log(data);
	    if (data.nombre === '' || data.email === '' || data.password === '') {
	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{
	      resgistrarUsuario(data).then(response =>{
	        if (response != false) {
	          window.location.reload();
	        }else{
	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      });
	    }
	}

	const verMas = async function(){

	    let porTipo = document.getElementById('tipoMostrar').value;

	    let limite = limiteUsuarios + 20;

	    let losUsuariosCliente = [];
	    let losUsuariosAdmin = [];

	    let cero = 0;

	    usuarios.forEach(element =>{

	      if (element.data.tipo == 'cliente') {

	        if (cero <= limite) {
	          losUsuariosCliente.push(element);
	        }
	      }else if (element.data.tipo == 'subAdmin') {

	        if (cero <= limite) {
	          losUsuariosAdmin.push(element);
	        }
	      }

	      cero++;
	    });


	    CambioUsuariosCliente(losUsuariosCliente);
	    CambioUsuariosAdmin(losUsuariosAdmin);
	    CambioLimiteUsuarios(limite);
	}

	const verPorTipo = async function(){

	    let porTipo = document.getElementById('tipoMostrar').value;

	    if (porTipo == 'cliente') {

	      document.getElementById('trCliente').setAttribute('style', '');
	      document.getElementById('trAdmin').setAttribute('style', 'display: none;');

	      document.getElementById('tbodyCliente').setAttribute('style', '');
	      document.getElementById('tbodyAdmin').setAttribute('style', 'display: none;');

	      if (usuariosCliente.length > 20) {
	        document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
	      }else{
	      	document.getElementById('verMas').setAttribute('style', 'display: none;');
	      }
	    }else if (porTipo == 'subAdmin') {

	      document.getElementById('trCliente').setAttribute('style', 'display: none;');
	      document.getElementById('trAdmin').setAttribute('style', '');

	      document.getElementById('tbodyCliente').setAttribute('style', 'display: none;');
	      document.getElementById('tbodyAdmin').setAttribute('style', '');

	      if (usuariosAdmin.length > 20) {
	        document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
	      }else{
	      	document.getElementById('verMas').setAttribute('style', 'display: none;');
	      }
	    }
	}

	const buscarUsuario = async function(){
      let query = document.getElementById('buscar').value;
      if (query === '') {

      		let porTipo = document.getElementById('tipoMostrar').value;
			let losUsuariosCliente = [];
			let losUsuariosAdmin = [];

			let cero = 0;

	        usuarios.forEach(element =>{

	              if (element.data.rol == 'cliente') {

	                if (cero <= limiteUsuarios) {
	                  losUsuariosCliente.push(element);
	                }
	              }else if (element.data.rol == 'subAdmin') {

	                if (cero <= limiteUsuarios) {
	                  losUsuariosAdmin.push(element);
	                }
	              }

	              cero++;
	        });

	        if (usuarios.length > 20) {
	            document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
	        }

	        CambioUsuariosCliente(losUsuariosCliente);
	        CambioUsuariosAdmin(losUsuariosAdmin);
      }else{

      	let porTipo = document.getElementById('tipoMostrar').value;

        let resultado = [];

        const datos = usuarios;

        const regex = new RegExp(`${query.trim()}`, 'i');

        const laBusqueda = datos.filter(datos => datos.data.email.search(regex) >= 0);

        let cero = 0;

        for (let buscado of laBusqueda) {
            if (cero <= limiteUsuarios) {

            	if (porTipo == buscado.data.rol) {

            		resultado.push(buscado);
            	}
            }
            cero++;
        }

        if (porTipo == 'cliente') {

        	CambioUsuariosCliente(resultado);
        }else if (porTipo == 'subAdmin') {

        	CambioUsuariosAdmin(resultado);
        }

        if (resultado.length > 20) {
	        document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
	    }else{
	      	document.getElementById('verMas').setAttribute('style', 'display: none;');
	    }
      }
  	}

  	const validarAccion = async function(item){

  		if (item.data.activo) {

  			CambioTextoModal('desactivar');
  			CambioEstadoUsuario(false);
  		}else{

  			CambioTextoModal('activar');
  			CambioEstadoUsuario(true);
  		}

  		CambioMostrarModalPreguntaUsuario(true);
  		CambioIdUsuario(item.id);
  	}

  	const removeUsuario = async function(){

  		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

  		actualizarEstadoUsuario(idUsuario, estadoUsuario).then(response =>{
  			window.location.reload();
  		})
  	}

  	const procesarCreditos = async function(){

  		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

  		if (cantidad === '') {

  			Swal.fire({
	            icon: 'info',
	            title: 'Complete todos los campos para continuar.',
	        });
  		}else if (isNaN(cantidad)) {

  			Swal.fire({
	            icon: 'info',
	            title: 'La cantidad de creditos debe ser numerica.',
	        });
  		}else{

  			asignarCreditos(idUsuario, cantidad).then(response =>{
  				window.location.reload();
  			})
  		}
  	}

  	const validarRevendedor = async function(item){

  		if (item.data.revendedor) {

  			CambioTextoModal('desactivar');
  			CambioEstadoUsuario(false);
  		}else{

  			CambioTextoModal('activar');
  			CambioEstadoUsuario(true);
  		}

  		CambioMostrarModalPreguntaRevendedor(true);
  		CambioIdUsuario(item.id);
  	}

  	const gestionarRevendedor = async function(){

  		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

  		actualizarRevendedor(idUsuario, estadoUsuario).then(response =>{
  			window.location.reload();
  		})
  	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuAdmin/>
				<div class="container">
					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={userImg}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">USUARIOS</h4>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<div class="form" style={{margin: 0,}}>

									<div class="headerFormLogin">
										<img
											src={userPlus}
											class="userPlus"
										/>
										<h4 class="tituloHeaderLogin">REGISTRAR SUB-ADMINISTRADOR</h4>
									</div>

									<div class="mb-3 containerInput">
										<img
											src={user}
											class="imgLabel"
										/>
									  	<input onChange={() => CambioNombre(document.getElementById('nombre').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="nombre" placeholder="Nombre *" />
									</div>

									<div class="mb-3 containerInput">
										<img
											src={mail}
											class="imgLabel"
										/>
									  	<input onChange={() => CambioEmail(document.getElementById('email').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="email" placeholder="Correo *" />
									</div>

									<div class="mb-3 containerInput">
										<img
											src={passwordImg}
											class="imgLabel"
										/>
									  	<input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña *" />
										<img
											src={mostrarPassword}
											class="imgLabel"
											onClick={verPassword}
										/>
									</div>

									<button onClick={procesarRegistro} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
										<img
											src={userPlusBlanco}
											class="imgBoton"
										/>
										REGISTRAR
									</button>

								</div>

						</div>
					</div><br/>

					<div class="row">
			            <div class="col-sm">

			              <div class="mb-3">
			                <label class="form-label">Ver por tipo:</label> <br/>
			                  <select onChange={verPorTipo} id="tipoMostrar" class="form-select form-select-lg mb-3" aria-label="Large select example">
			                    <option value="subAdmin">Administradores</option>
			                    <option value="cliente">Cliente</option>
			                  </select>
			              </div>

			            </div>
			          </div><br/>

			        <div class="row">
						<div class="col-sm">

							<div class="mb-3 containerInput">
								<img
									src={user}
									class="imgLabel"
								/>
								<input onChange={buscarUsuario} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="buscar" placeholder="Buscar Email" />
							</div>

						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<table class="table">
								  <thead>

								    <tr id="trAdmin">
								      <th scope="col">Nombre</th>
								      <th scope="col">Email</th>
								      <th scope="col">Acción</th>
								    </tr>

								    <tr id="trCliente" style={{display: 'none',}}>
								      <th scope="col">Nombre</th>
								      <th scope="col">Email</th>
								      <th scope="col">Telefono</th>
								      <th scope="col">Revendedor</th>
								      <th scope="col">Acción</th>
								    </tr>

								  </thead>
								  <tbody id="tbodyCliente" style={{display: 'none',}}>
								  	{usuariosCliente.map((item) =>(
								  		<tr>
									      	<td>{item.data.nombre}</td>
									      	<td>{item.data.email}</td>
									      	<td>{item.data.telefono}</td>
									      	<td>

									      		<BotonAccion
									      			accion={() => validarRevendedor(item)}
													mostrar={item.data.revendedor}
									      		/>

									      	</td>
									      	<td>
									      		<BotonAccion
									      			accion={() => validarAccion(item)}
													mostrar={item.data.activo}
									      		/>
									      		<button onClick={() => {
										    		CambioModalAsignarCreditos(true);
										    		CambioIdUsuario(item.id);
										    	}} class="btn btn-warning">
										    		Asignar Creditos
										    	</button>
									      	</td>
									    </tr>
								  	))}
								  </tbody>
								  <tbody id="tbodyAdmin">
								  	{usuariosAdmin.map((item) =>(
								  		<tr>
									      <td>{item.data.nombre}</td>
									      <td>{item.data.email}</td>
									      <td>
									      	<BotonAccion
									      		accion={() => validarAccion(item)}
												mostrar={item.data.activo}
									      	/>
									      </td>
									    </tr>
								  	))}
								  </tbody>
							</table>

							</div>
						</div>
					</div>

					<div class="row">
			            <div class="col-sm">

			              <button style={{display: 'none',}} onClick={verMas} class="btn btn-primary" id="verMas">
			                Ver más
			              </button><br/><br/>

			            </div>
			        </div>

			        <Modal mostrar={mostrarModalPreguntaUsuario}>
				        <div style={{padding: 20,}}>
				            <h5 class="tituloIniciarSesion sinMargenes">¿Desea {textoModal} este usuario?</h5>
				            <div style={{textAlign: 'center',}}>
				                <button class="btn btn-primary" onClick={removeUsuario}>Si</button>
				                <button onClick={() => {
				                  CambioMostrarModalPreguntaUsuario(false);
				                  CambioIdUsuario(false);
				                }} style={{margin: 5,}} class="btn btn-danger">No</button>
				            </div>
				        </div>
				    </Modal>

				    <Modal mostrar={modalAsignarCreditos}>
					    <p onClick={() => {
					    	CambioModalAsignarCreditos(false);
					    	CambioIdUsuario(false);
					    }} class="equix">X</p>
					    <div class="containerRecuperar">
					        <h5 class="tituloIniciarSesion sinMargenes">CAMBIAR CONTRASEÑA</h5>

					        <div class="mb-3 containerInput">
								<img
									src={recargasImg}
									class="imgLabel"
								/>
								<input onChange={() => CambioCantidad(document.getElementById('cantidad').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="cantidad" placeholder="Cantidad *" />
							</div>
					        <button onClick={procesarCreditos} class="btn btn-info" style={{marginTop: 20,}}>GUARDAR</button>
					    </div>
					</Modal>

					<Modal mostrar={mostrarModalPreguntaRevendedor}>
				        <div style={{padding: 20,}}>
				            <h5 class="tituloIniciarSesion sinMargenes">¿Desea {textoModal} revendedor?</h5>
				            <div style={{textAlign: 'center',}}>
				                <button class="btn btn-primary" onClick={gestionarRevendedor}>Si</button>
				                <button onClick={() => {
				                  CambioMostrarModalPreguntaRevendedor(false);
				                  CambioIdUsuario(false);
				                }} style={{margin: 5,}} class="btn btn-danger">No</button>
				            </div>
				        </div>
				    </Modal>
				</div>
		</AnimatedPage>
	);
}

export default AdminUsersScreen;