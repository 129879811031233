import React, { useState, useEffect } from 'react';
import './usuarios-styles.css';

export function BotonAccion({
	accion,
	mostrar
}){

	if (mostrar) {

		return(
			<button onClick={accion} class="btn btn-danger">Desactivar</button>
		);
	}else{

		return(
			<button onClick={accion} class="btn btn-success">Activar</button>
		);
	}
}