import React, { useState, useEffect } from 'react';
import './home-styles.css';
import {MenuApp, MenuAdmin} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {passwordImg, userImg, fotoImg} from '../../../global/imgs';
import {guardarNuevaPassword} from '../../../backend/perfil/perfil';
import Swal from 'sweetalert2';
import {Modal} from '../../../components/modals/modals';
import {
	guardarImagen, 
	consultarGaleria, 
	eliminarFoto, 
	guardarPublicidadRevendedores, 
	verPublicidad,
	eliminarPublicidad
} from '../../../backend/galeria/galeria';
import {AnimatedPage} from '../../../components/animacion/animacion';

function AdminHomeScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [password, CambioPassword] = useState('');
	const [modalPassword, CambioModalPassword] = useState(false);
	const [passwordNueva, CambioPasswordNueva] = useState('');
	const [passwordConfirmar, CambioPasswordConfirmar] = useState('');
	const [galeria, CambioGaleria] = useState([]);
	const [busquedaGaleria, CambioBusquedaGaleria] = useState(false);
	const [idFoto, CambioIdFoto] = useState(false);
	const [mostrarModalPreguntaFoto, CambioMostrarModalPreguntaFoto] = useState(false);
	const [busquedaPublicidad, CambioBusquedaPublicidad] = useState(false);
	const [imagenesPublicidad, CambioImagenesPublicidad] = useState([]);
	const [mostrarModalPreguntaPublicidad, CambioMostrarModalPreguntaPublicidad] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaGaleria == false) {

			consultarGaleria().then(response =>{

				CambioGaleria(response);
			})
			CambioBusquedaGaleria(true);
		}

		if (busquedaPublicidad == false) {

			verPublicidad().then(response =>{

				if (response != false) {

					CambioImagenesPublicidad([response]);
				}
			})

			CambioBusquedaPublicidad(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

			}else{

				window.location.href = '/';
			}
		}else{

			window.location.href = '/';
		}
	}

	const procesarPassword = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    if (password === '' || passwordNueva === '' || passwordConfirmar === '') {

	    	Swal.fire({
			    icon: 'info',
			    title: 'Complete todos los campos para continuar.',
			});
	    }else if (passwordNueva != passwordConfirmar) {

	    	Swal.fire({
			    icon: 'info',
			    title: 'La contraseña no coincide.',
			});
	    }else{

	    	guardarNuevaPassword(password, passwordNueva).then(response =>{

	    		if (response != false) {

	    			window.location.reload();
	    		}else{

	    			Swal.fire({
			            icon: 'error',
			            title: 'Lo sentimos pero no pudimos actualizar sus datos.',
			        });
	    		}
	    	})
	    }
	}

	const visualizarNombreArchivo = async function(idElement, idLabel){

		let fileInput = document.getElementById(idElement);   
		let filename = fileInput.files[0].name; console.log(filename);
		document.getElementById(idLabel).innerHTML = filename;
	}

	const procesarFoto = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
	    let id = "";
	      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	    let foto = document.getElementById('imgCategoria').files[0];

	    if (foto === undefined) {

			Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
		}else{

			guardarImagen(id, foto).then(response =>{
				window.location.reload();
			})
		}
	}

	const removeFoto = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    eliminarFoto(idFoto).then(response =>{
	      window.location.reload();
	    })
	}

	const procesarFoto2 = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
	    let id = "";
	      for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	    let foto = document.getElementById('imgCategoria2').files[0];

	    if (foto === undefined) {

			Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
		}else{

			guardarPublicidadRevendedores(id, foto).then(response =>{
				window.location.reload();
			})
		}
	}

	const removePublicidad = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    eliminarPublicidad().then(response =>{
	      window.location.reload();
	    })
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuAdmin/>
				<div class="container">

					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={userImg}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">PERFIL</h4>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<button onClick={() =>{
								CambioModalPassword(true);
							}} class="btn btn-success" style={{display: 'block', margin: 'auto', marginTop: 20,}}>Editar Contraseña</button>

						</div>
					</div><br/><br/>

					<div class="row">
						<div class="col-sm">
							<div class="form" style={{margin: 0,}}>
									<div class="headerFormLogin">
										<img
											src={fotoImg}
											class="userPlus"
										/>
										<h4 class="tituloHeaderLogin">AGREGAR A GALERIA</h4>
									</div>

									<label for="imgCategoria" style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
										<span id="labelImgCategoria">SUBIR IMAGEN</span>
										<input onChange={() => visualizarNombreArchivo('imgCategoria', 'labelImgCategoria')} type="file" id="imgCategoria" style={{display: 'none',}} accept="image/*" />
									</label>

									<button onClick={procesarFoto} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
										AGREGAR
									</button>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<table class="table">
								<thead>
									<tr>
									    <th scope="col">Foto</th>
									    <th scope="col">Acción</th>
									</tr>
								</thead>
								<tbody>
									{galeria.map((item) =>(
									  	<tr>
										    <td>
										    	<img
										      		src={item.data.foto}
										      		style={{width: 100,}}
										      	/>
										    </td>
										    <td>
										      	<button onClick={() => {
						                          CambioMostrarModalPreguntaFoto(true);
						                          CambioIdFoto(item.id);
						                        }} class="btn btn-danger">
										      		Eliminar
										      	</button>
										    </td>
										</tr>
									))}
								</tbody>
							</table>

						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">
							<div class="form" style={{margin: 0,}}>
									<div class="headerFormLogin">
										<img
											src={fotoImg}
											class="userPlus"
										/>
										<h4 class="tituloHeaderLogin">AGREGAR IMAGEN DE PUBLICIDAD PARA REVENDEDORES</h4>
									</div>

									<label for="imgCategoria2" style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
										<span id="labelImgCategoria2">SUBIR IMAGEN</span>
										<input onChange={() => visualizarNombreArchivo('imgCategoria2', 'labelImgCategoria2')} type="file" id="imgCategoria2" style={{display: 'none',}} accept="image/*" />
									</label>

									<button onClick={procesarFoto2} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
										AGREGAR
									</button>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<table class="table">
								<thead>
									<tr>
									    <th scope="col">Foto</th>
									    <th scope="col">Acción</th>
									</tr>
								</thead>
								<tbody>
									{imagenesPublicidad.map((item) =>(
									  	<tr>
										    <td>
										    	<img
										      		src={item.foto}
										      		style={{width: 100,}}
										      	/>
										    </td>
										    <td>
										      	<button onClick={() => {
						                          CambioMostrarModalPreguntaPublicidad(true);
						                        }} class="btn btn-danger">
										      		Eliminar
										      	</button>
										    </td>
										</tr>
									))}
								</tbody>
							</table>

						</div>
					</div><br/>

				</div>
			</div>

			<Modal mostrar={modalPassword}>
			    <p onClick={() => {
			    	CambioModalPassword(false);
			    }} class="equix">X</p>
			    <div class="containerRecuperar">
			        <h5 class="tituloIniciarSesion sinMargenes">CAMBIAR CONTRASEÑA</h5>

			        <div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPassword(document.getElementById('password').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="password" placeholder="Contraseña Actual *" />
					</div>

					<div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPasswordNueva(document.getElementById('passwordNueva').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="passwordNueva" placeholder="Contraseña Nueva *" />
					</div>

					<div class="mb-3 containerInput">
						<img
							src={passwordImg}
							class="imgLabel"
						/>
						<input onChange={() => CambioPasswordConfirmar(document.getElementById('passwordConfirmar').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="password" class="form-control inputLogin" id="passwordConfirmar" placeholder="Confirmar Contraseña *" />
					</div>
			        <button onClick={procesarPassword} class="btn btn-info" style={{marginTop: 20,}}>GUARDAR</button>
			    </div>
			</Modal>

			<Modal mostrar={mostrarModalPreguntaFoto}>
		        <div style={{padding: 20,}}>
		            <h5 class="tituloIniciarSesion sinMargenes">¿Desea eliminar esta foto?</h5>
		            <div style={{textAlign: 'center',}}>
		                <button class="btn btn-primary" onClick={removeFoto}>Si</button>
		                <button onClick={() => {
		                  CambioMostrarModalPreguntaFoto(false);
		                  CambioIdFoto(false);
		                }} style={{margin: 5,}} class="btn btn-danger">No</button>
		            </div>
		        </div>
		    </Modal>
		    
		    <Modal mostrar={mostrarModalPreguntaPublicidad}>
		        <div style={{padding: 20,}}>
		            <h5 class="tituloIniciarSesion sinMargenes">¿Desea eliminar esta foto?</h5>
		            <div style={{textAlign: 'center',}}>
		                <button class="btn btn-primary" onClick={removePublicidad}>Si</button>
		                <button onClick={() => {
		                  CambioMostrarModalPreguntaPublicidad(false);
		                }} style={{margin: 5,}} class="btn btn-danger">No</button>
		            </div>
		        </div>
		    </Modal>
		</AnimatedPage>
	);
}

export default AdminHomeScreen;