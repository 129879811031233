import React, { useState, useEffect } from 'react';
import './productos-styles.css';
import {consultarProductos, consultarCompras} from '../../backend/productos/productos';
import {consultarPerfiles, terminarEliminarPerfil, consultarCredenciales} from '../../backend/credenciales/credenciales';
import {datosDelPais} from '../../backend/paises/paises';
import Swal from 'sweetalert2';
import {Modal} from '../../components/modals/modals';
import {meses} from '../../global/datosCalendario';
import {carritoBlanco, monitorImg} from '../../global/imgs';

export function NombreProducto({idProducto}) {
	
	const [nombre, CambioNombre] = useState('');
	const [busquedaProductos, CambioBusquedaProductos] = useState(false);

	useEffect(() =>{

		if (busquedaProductos == false) {

			consultarProductos().then(response =>{

				response.forEach(element =>{

					if (element.id == idProducto) {
						CambioNombre(element.data.nombre);
					}
				})
			});

			CambioBusquedaProductos(true);
		}
	})

	return(
		<span>{nombre}</span>
	);
}

export function ListaPerfiles({idCredencial, cuentaCompleta}){

	const [busquedaPerfiles, CambioBusquedaPerfiles] = useState(false);
	const [perfiles, CambioPerfiles] = useState([]);
	const [idPerfil, CambioIdPerfil] = useState(false);
	const [mostrarModalPreguntaPerfil, CambioMostrarModalPreguntaPerfil] = useState(false);

	useEffect(() =>{

		if (busquedaPerfiles == false) {

			consultarPerfiles(idCredencial).then(response =>{

				CambioPerfiles(response);
				CambioMostrarModalPreguntaPerfil(false);
				Swal.close();
			});

			CambioBusquedaPerfiles(true);
		}
	});

	const desaparecerEstePerfil = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    terminarEliminarPerfil(idPerfil).then(response =>{
	      CambioBusquedaPerfiles(false);
	    })
	}

	if (cuentaCompleta == true) {

		return(
			<>
				<span style={{color: '#fff', fontWeight: 'bold',}}>Cuenta Completa</span>
			</>
		);
	}else{

		return(
			<>
				{perfiles.map((item) =>(
					<div class="perfilCliente">
	                  <p onClick={() => {
		                CambioMostrarModalPreguntaPerfil(true);
		                CambioIdPerfil(item.id);
		              }} class="equix">X</p>
	                  <span class="datoPerfil">{item.data.cliente}</span><br/>
	                  <span class="datoPerfil">{item.data.password}</span>
	                </div>
				))}
				<Modal mostrar={mostrarModalPreguntaPerfil}>
		          <div style={{padding: 20,}}>
		            <h5 class="tituloIniciarSesion sinMargenes">¿Desea eliminar este perfil?</h5>
		              <div style={{textAlign: 'center',}}>
		                <button class="btn btn-primary" onClick={desaparecerEstePerfil}>Si</button>
		                <button onClick={() => {
		                  CambioMostrarModalPreguntaPerfil(false);
		                  CambioIdPerfil(false);
		                }} style={{margin: 5,}} class="btn btn-danger">No</button>
		              </div>
		          </div>
		      </Modal>
			</>
		);
	}
}

export function Temporizador({
	calculo
}){

	const [fechaPuesta, CambioFechaPuesta] = useState(false);
	const [fechaFinal, CambioFechaFinal] = useState('');

	useEffect(() =>{

		if (fechaPuesta == false) {

			ponerFecha();
			CambioFechaPuesta(true);
		}
	})

	const ponerFecha = async function(){

		let date = new Date();

		let dia = date.getDate();
		let mes = meses[date.getMonth()].numeroMes;
		let ano = date.getFullYear();

		let diasMes = meses[date.getMonth()].dias;

		let fecha = '';

		if (calculo == 10 || calculo == 15) {

			let sumaDias = '';

			if (calculo == 10) {

				sumaDias = dia + 10;
			}else if (calculo == 15) {

				sumaDias = dia + 15;
			} 

			if (sumaDias > diasMes) {

				let sumaMes = date.getMonth() + 1;

				if (sumaMes > 11) {

					mes = meses[0].numeroMes;

					let sumaAno = ano + 1;

					ano = sumaMes;
				}else{

					mes = meses[sumaMes].numeroMes;
				}

				/*for (let i = 0; i <= calculo; i++) {
					
					if (dia > diasMes) {

						i = 1;
					}

					dia++; 
				}*/

				fecha = ano+'-'+mes+'-'+dia;

				CambioFechaFinal(fecha);
			}else{

				for (let i = 0; i <= calculo; i++) {
					
					if (dia > diasMes) {

						i = 1;
					}

					dia++; 
				}

				fecha = ano+'-'+mes+'-'+dia;

				CambioFechaFinal(fecha);
			}
		}else if (calculo == '1Mes') {

			let sumaMes = date.getMonth() + 1;

			if (sumaMes > 11) {

				mes = meses[0].numeroMes;

				let sumaAno = ano + 1;

				ano = sumaMes;
			}else{

				mes = meses[sumaMes].numeroMes;
			}

			if (sumaMes == 1 && dia == 30 || dia == 31) {

				dia = 28;
			}

			fecha = ano+'-'+mes+'-'+dia;

			CambioFechaFinal(fecha);
		}
	}

	return(
		<span style={{color: '#fff',}}>Si lo compras hoy venceria el: <span style={{color: '#e3a782', fontWeight: 'bold',}}>{fechaFinal}</span></span>
	);
}

export function CantidadStock({
	producto
}){

	const [busquedaCredenciales, CambioBusquedaCredenciales] = useState(false);
	const [credenciales, CambioCredenciales] = useState(0);
	const [colorNumero, CambioColorNumero] = useState('green');

	useEffect(() =>{

		if (busquedaCredenciales == false) {

			ponerStock();

	      	CambioBusquedaCredenciales(true)
	    }
	});

	const ponerStock = async function(){

		let listCredenciales = await consultarCredenciales();

		let lasCredenciales = [];

		listCredenciales.forEach(element =>{

		    if (element.data.idProducto == producto.id) {

		        lasCredenciales.push(element);
		    }
		});

		let cero = 0;

		let quitarBoton = true;

		for (let credencial of lasCredenciales) {
			
			if (credencial.data.cuentaCompleta == true) {

				let suma = cero + 1;

				cero = suma;

				quitarBoton = false;
			}else{

				let losPerfiles = await consultarPerfiles(credencial.id);

				let suma = cero + losPerfiles.length;

				cero = suma;
			}
		}

		if (quitarBoton == true) {

			document.getElementById('estilosBotonCuentaCompleta-'+producto.id).innerHTML = '#cuentaCompleta-'+producto.id+'{display: none;}';
		}

		if (producto.data.tipo == 'tarjeta') {

			if (lasCredenciales.length == 0) {
			    document.getElementById('botonComprar-'+producto.id).innerHTML = '<div>'+
												
					'</div>'+
						'<div class="btn btn-primary" style="background: #0020c7; opacity: 0.5;">'+
							'<img'+
								'src="'+carritoBlanco+'"'+
								'style="width: 30px;"'+
							'/>'+
							'Sin Stock'+
					'</div>';

				CambioColorNumero('red');
			}

			CambioCredenciales(lasCredenciales.length);
		}else if (producto.data.tipo == 'cuenta') {

			if (cero == 0) {
			    document.getElementById('botonComprar-'+producto.id).innerHTML = '<div>'+
												
					'</div>'+
						'<div class="btn btn-primary" style="background: #0020c7; opacity: 0.5;">'+
							'<img'+
								'src="'+carritoBlanco+'"'+
								'style="width: 30px;"'+
							'/>'+
							'Sin Stock'+
					'</div>';

				CambioColorNumero('red');
			}

			CambioCredenciales(cero);
		}
	}

	return(
		<div class="dataStock">
			<span style={{color: '#fff', fontWeight: 'bold',}}>
				Stock:
			</span>
			<img
				src={monitorImg}
				style={{width: 15, marginRight: 5,}}
			/>
			<span style={{color: colorNumero, fontWeight: 'bold',}}>
				{credenciales}
			</span>
		</div>
	);
}

export function InfoRegion({
	idPais,
	tipoInfo
}){

	const [busquedaPais, CambioBusquedaPais] = useState(false);
	const [nombrePais, CambioNombrePais] = useState('');
	const [bandera, CambioBandera] = useState('');

	useEffect(() =>{

		if (busquedaPais == false) {

			datosDelPais(idPais).then(response =>{

				if (response != false) {

					CambioNombrePais(response.data.nombre);
					CambioBandera(response.data.foto);
				}
			})

			CambioBusquedaPais(true);
		}
	})

	if (tipoInfo == 'producto') {

		return(
			<>	
				<img
					src={bandera}
					class="bandera"
				/>
			</>
		);
	}else if (tipoInfo == 'carrito') {

		return(
			<span>Localización: {nombrePais}</span>
		);
	}else{

		return(
			<></>
		);
	}
}

export function NumeroCompras({
	idProducto3
}){

	const [busquedaCompras, CambioBusquedaCompras] = useState(false);
	const [compras, CambioCompras] = useState(0);

	useEffect(() =>{

		if (busquedaCompras == false) {

			consultarCompras(idProducto3).then(response =>{

				CambioCompras(response.length);
			});

			CambioBusquedaCompras(true);
		}
	})

	return(
		<span>{compras}</span>
	);
}